import {Modal} from "react-bootstrap";

function LoginModal(props) {
    return (
        <Modal {...props} centered className={'login-modal'}>
      
                

            <Modal.Body className={'contentmodal py-4'}>
                <img src={`/images/man.jpeg`} className="popsimg" />
            <div className="text-warning pt-3">
            📢 ປະກາດແຈ້ງປິດປັບປຸງ 
ວັນທີ 01/06/2023 
ເວລາ 06:00-10:30 ນ 
ຂໍໂທດໃນຄວາມບໍ່ສະດວກ 🙏🥰
            </div>
            </Modal.Body>
        </Modal>
    );
}

export default LoginModal;
