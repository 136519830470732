import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { getwebinfo } from '../store/user'
import { LG } from '../config/Index'
const lgtext = {
    thai:{
      1:'ติดต่อ สอบถาม',
      2:'เฟส',
      3:'โทร',
      4:'ไลน์',
      5:'ช่องทางการติดต่อ',
    },
    lao:{
      1:'ຕິດຕໍ່ ສອບຖາມ',
      2:'ເຟສບຸກ',
      3:'ວັອດແອັບ',
      4:'ລາຍ',
      5:'ຊ່ອງທາງການຕິດຕໍ່',
    }
  }
function Contact() {
    const dispatch = useDispatch()
    useEffect(()=> {
        dispatch(getwebinfo({}))
    } ,[])
    const { webinfo:contact } = useSelector(state => state.user)
    const [banners] = useState([
        {name: 'banner1', image: './images/promotion/pro0.png'},
        {name: 'banner2', image: './images/promotion/pro1.png'},
        {name: 'banner3', image: './images/promotion/pro2.png'},
        {name: 'banner4', image: './images/promotion/pro3.png'},
        {name: 'banner5', image: './images/promotion/pro4.png'}
    ]) || [];

    return (
        <div>
            <div className="headsecion pb-5">
                 {lgtext[LG]['5']}
            </div>

            <div className="ctpersonal promotion">
                <div className="gridingame full">

              
              <div style={{ height: 150 }} className="text-center">
                 <a href={contact?.facebookurl} target="bank"><img src="/images/facebook.png" height={70} ></img></a> 
                 <a href={contact?.facebookurl} target="bank"> <div className="btnsubmit44" >
                 {lgtext[LG]['2']} : {contact?.facebookid}
                </div>
                </a>
              </div>
              
              <div style={{ height: 150 }} className="text-center">
              <a href={`${contact?.whatappurl}`} target="bank"> <img src="/images/whatsapp-logo.png" height={80} ></img></a>
              <a href={`${contact?.whatappurl}`} target="bank"><div className="btnsubmit55" >
              {lgtext[LG]['3']} : {contact?.whatappid}
                </div>
                </a>
              </div>
              
              <div style={{ height: 150 }} className="text-center">
              <a href={contact?.lineurl} target="bank"> <img src="/images/line-logo2.png" height={80} ></img></a>
              <a href={contact?.lineurl} target="bank"><div className="btnsubmit55" >
              {lgtext[LG]['4']} : {contact?.lineid}
                </div>
                </a>
              </div>

                </div>
            </div>
        </div>
    );
}

export default Contact;
