import {useState, useEffect, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";
import { LG, addCommas } from '../config/Index'
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getaffdata, affwithdraw, closecashbackalert } from '../store/user'
import ModelApprove from '../components/ModalCashback'
import ModalLoading from '../components/ModalLoading'
import QRCode from "qrcode";
const lgtext = {
    lao:{
      1:'ແນະນຳໝູ່',
    },
    thai:{
      1:'แนะนำเพื่อน',
     
    }
  }
function Affiliate() {

    const { affitem, cashbackalert, btnLoading, isLoading } = useSelector(state => state.user)
    const dispatch = useDispatch()
    useEffect(()=> {
        dispatch(getaffdata())
    },[])
    const [type, setType] = useState('overview');
    const [copied, setcopied] = useState(false);

    const [qrcode, setQrcode] = useState('')
    const url = affitem?.afflink
    const genqr = () => {
        QRCode.toDataURL(url, (err, url )=> {
            if(err) return console.log(err)
            setQrcode(url)
        })
    }

    useMemo(()=> {
        genqr()
    }, [affitem?.afflink])
  const setCopyall = () => {
    setcopied(true);
  };

  const onSubmit = () => {
    if(btnLoading === false) {
        dispatch(affwithdraw())
    }
    
  }

  const getmounth =(e)=> {
    console.log(e.target.value)
  }

    return (
        <div>
            <div className="headsecion">
                <i className="fas fa-users"/>  {lgtext[LG]['1']}
            </div>

            <div className="porela containlinkfriend text-center">
                                <label className={'mb-3'}>ลิงค์ชวนเพื่อน&nbsp;</label>
                               
                                <CopyToClipboard
              text={`${affitem?.afflink}`}
              onCopy={() => setCopyall()}
            >
                <button className="btn btnback" >คัดลอก</button>
                 {
                    /*
<input type="text" id="friendlink" className={`${copied ? `loginform01 copylinkac`:`loginform01 copylink`}`}
                                       disabled value={affitem?.afflink}/>
                    */
                 }
                 
                </CopyToClipboard>
               
                <input type="text" id="friendlink" className={`${copied ? `loginform01 copylink1ac`:`loginform01 copylink1`}`}
                                       disabled value={affitem?.afflink}/>
                            </div>

                            <div className="text-center">QRCode ชวนเพื่อน</div>
                            <div className="text-center"> <img src={qrcode} /></div>
                            <div className="text-center pt-1"><a className="btn btnback" href={qrcode} download='sbo95th_qr.png' >ดาวโหลด</a></div>
                            

            <div className="ctpersonal affilicate">
                <div className={'containwd my-3 p-2'}>
                    <div className={'row'}>
                        <div className={'col-12 col-sm'}>

                            <div className={'account-card mb-3'}>

                                <div className={'text-center my-2'}>
                                    <div className={'header'}>บัญชีรับรายได้ของคุณ</div>
                                </div>
                               

                                <div className={'d-flex align-items-center justify-content-center gap-3'}>
                                    <div>
                                        <img className={'bank-logo'} src={affitem?.bankphoto}/>
                                    </div>
                                    <div>
                                        <div className={'mb-2'}>
                                            {affitem?.bankname} <br/>
                                            ชื่อบัญชี :  {affitem?.accname} <br />
                                            เลขบัญชี:  {affitem?.accno} <br />
                                        </div>

                                        <div className={'small '}>
                                            รายได้ปัจจุบัน:
                                        </div>

                                        <h5 className={'income'}>
                                            {affitem?.money}
                                        </h5>
                                    </div>
                                </div>
                                <div className={'text-center my-2 pt-3'}>
                                    <div className={'header'}>{affitem?.fulldate}</div>
                                    {
                                        affitem?.payout === 'ok' && parseFloat(affitem?.paymentday) === 0 && parseFloat(affitem?.money) >= parseFloat(affitem?.minaff) && <button type="button" onClick={()=>onSubmit()} className="btnLogin my-3">
                                        {
                                            btnLoading === true ? <span>กำลังตรวจสอบ</span> : <span>รับยอด แนะนำเพื่อน</span>
                                        }
                                        
                                    </button>
                                    }

{
                                        affitem?.payout === 'ok' && parseFloat(affitem?.paymentday) === 0 && parseFloat(affitem?.money) < parseFloat(affitem?.minaff) && <button type="button" className="btnLogin my-3">
                                        {
                                            btnLoading === true ? <span>กำลังตรวจสอบ</span> : <span>ยอดรายได้ ไม่พอถอน</span>
                                        }
                                        
                                    </button>
                                    }

                                    {
                                        affitem?.payout === 'ok' && parseFloat(affitem?.paymentday) === 1 && <button type="button" className="btnAffrecive my-3">
                                        <span>วันนี้ คุณรับยอดเงินแล้ว</span>
                                    </button>
                                    }
                        
                                    
                                </div>
                                {
                                    /*
<div className="btn-withdraw p-2">แจ้งถอนรายได้</div>
                                    */
                                }
                                
                            </div>
                         
                          
                        </div>

                        <div className={'col-12 col-sm'}>


                            <div className="wrapgrid001">
                                <div className="inwrapgrid001">
                                    <div className={`ininwrapgrid001 ${type === 'overview' ? "active" : ""}`}
                                         onClick={() => setType('overview')}>
                                        <i className="far fa-handshake"/><br/>
                                        ภาพรวม
                                    </div>
                                </div>
                                <div className="inwrapgrid001">
                                    <div className={`ininwrapgrid001 ${type === 'friend' ? "active" : ""}`}
                                         onClick={() => setType('friend')}>
                                        <i className="far fa-handshake"/><br/>
                                        เพื่อน
                                    </div>
                                </div>
                                <div className="inwrapgrid001">
                                    <div className={`ininwrapgrid001 ${type === 'income' ? "active" : ""}`}
                                         onClick={() => setType('income')}>
                                        <i className="far fa-handshake"/><br/>
                                        ประวัติรายได้
                                    </div>
                                </div>
                            </div>


                            <div className={`containinputwd ${type === 'overview' ? "active" : ""}`}>
                                <div className={'mb-3 text-center'}>
                                    <div className={'header'}>รายละเอียดแนะนำเพื่อน</div>
                                </div>

                                <table className="mt-2 levelfriend mb-3">
                                    <tbody>
                                    <tr>
                                        <td className="text-start">
                                            <i className="fad fa-coins"/> <span>ส่วนแบ่งรายได้</span>
                                        </td>
                                        <td className="text-end">
                                            <span>{affitem?.affper} %</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-start">
                                            <i className="fad fa-coins"/> <span>คำนวนจาก</span>
                                        </td>
                                        <td className="text-end">
                                            <span>ยอดเสีย</span>
                                        </td>
                                    </tr>
                                    {
                                        /*
  <tr>
                                        <td className="text-start">
                                            <i className="fad fa-coins"/> <span>ส่วนแบ่งรายได้ชั้นที่ 2</span>
                                        </td>
                                        <td className="text-end">
                                            <span>5 %</span>
                                        </td>
                                    </tr>
                                        */
                                    }
                                  
                                    </tbody>
                                </table>

                                <div className={'text-center'}>
                                    <span className="detailaf">รายละเอียด</span>
                                    <div role="alert" className="indetail">
                                        <div className="row m-0">
                                            <div className="col-6 p-0 text-start">
                                                <span>เพื่อนทั้งหมด</span>
                                            </div>
                                            <div className="col-6 p-0 text-end">{addCommas(parseFloat(affitem?.membertotal).toFixed(0))}</div>
                                         
                                        </div>
                                      
                                        <div className="row m-0">
                                            <div className="col-6 p-0 text-start">
                                                <span className='cashbacknb1'>ยอด TurnOver</span>
                                            </div>
                                            <div className="col-6 p-0 text-end"><span className='cashbacknb1'>{addCommas(parseFloat(affitem?.turnover).toFixed(0))}</span></div>
                                          
                                        </div>
                                        <div className="row m-0">
                                            <div className="col-6 p-0 text-start">
                                                <span className='cashbacknb1'>ยอด Win/loss</span>
                                            </div>
                                            <div className="col-6 p-0 text-end"><span className='cashbacknb1'>{addCommas(parseFloat(affitem?.winloss).toFixed(0))}</span></div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`containinputwd ${type === 'friend' ? "active" : ""}`}>
                                <div className={'text-center'}>
                                    <span className="detailaf">รายชื่อเพื่อน</span>
                                </div>
                                <div className="divoffriends">
                                   {
                                    affitem?.memberitems && affitem?.memberitems.map(x=> <span key={x.id} className="affbox2">{x.name}</span>)
                                   }
                                  
                                </div>
                            </div>

                            <div className={`containinputwd ${type === 'income' ? "active" : ""}`}>
                                <div>
                                  
                                    <div className="divoffriends">
                                       
                                        {
                                    affitem?.itemsaff && affitem?.itemsaff.map(x=> <div key={x.id} className="txtmoneyaff" >{x.dtnow} / รายได้ <span className="affbox3"> {addCommas(parseFloat(x.amount).toFixed(0))}</span></div>)
                                   }
                                    </div>
                                    
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div className="modalspanbox mt-3 text-start">
                <div>
                เงื่อนไข<br />
คำนวนจากรายได้ยอดเสียเพื่อนที่แนะนำ<br />
✅ นับวันที่ 1-15 จ่ายวันที่ 16 และ นับจากวันที่ 16 ถึงสิ้นเดือน จ่ายวันที่1 (หากท่านมียอดเสียในระยะเวลาที่แจ้ง แต่ท่านไม่แจ้งรับ ในวันที่แจ้งจ่ายของเว็ป ยอดรายได้ของท่านจะถูกตัดทิ้งและเริ่มคำนวนใหม่ในรอบถัดไปทันที)<br />
✅ จ่ายคืนยอดเสียเพื่อน <span className='cashbacknb1'>ขั้นต่ำ {addCommas(parseFloat(affitem?.minaff || 0).toFixed(0))} บาท </span><br />
✅ จ่ายคืนยอดเสียเพื่อนสูงสุด <span className='cashbacknb1'>ไม่เกิน {addCommas(parseFloat(affitem?.maxaff || 0).toFixed(0))} บาท </span> ต่อเดือน<br />
✅ ผู้แนะนำจะต้องมี <span className='cashbacknb1'>ยอดเทิร์น {addCommas(parseFloat(affitem?.minbet || 20000).toFixed(0))}</span> ขึ้นไป ถึงจะแจ้งรับ ยอดได้<br />
✅ ขั้นตอนรับรายได้ ท่านสามารถกดปุ่ม <span className="cashbacknb1">“รับยอด แนะนำเพื่อน”</span> <br />
✅ คำนวณยอดเสียเพื่อน {affitem?.fulldate} รับเงินวันที่ <span className='cashbacknb1'> {affitem?.payoutday}</span> วันเดียวเท่านั้น
                              
                            </div>
                 
               </div>
            </div>
            
            <ModelApprove show={cashbackalert} onHide={()=>{ dispatch(closecashbackalert({}))}} />
            <ModalLoading show={isLoading} onHide={()=>{}} />
        </div>
    );
}

export default Affiliate;
