import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LG, addCommas } from '../config/Index'
import { checkcashback, cashbackwithdraw, closecashbackalert } from '../store/user';
import ModelApprove from '../components/ModalCashback'
import ModalLoading from '../components/ModalLoading'
const lgtext = {
    lao:{
      1:'ຄືນຍອດເສຍ',
      2:'ບໍ່ມີຂໍ້ມູນ',
    },
    thai:{
      1:'แคชแบ็ก',
      2:'ไม่มีข้อมูลการเดิมพัน',
     
    }
  }
function CashBack() {
    const dispatch = useDispatch()
    const { cashback, btnLoading, cashbackalert, isLoading } = useSelector(state => state.user )
    useEffect(()=> {
        dispatch(checkcashback({}))
    },[])

    const onSubmit = () => {
        if(btnLoading === false){
            dispatch(cashbackwithdraw({}))
        }
    }
    return (
        <div>
            <div className="headsecion">
                <img src="images/icon/return.png"/>  {lgtext[LG]['1']}
            </div>

            <div className="ctpersonal trans text-center">
            {
            //lgtext[LG]['2']
           }
               
                      <div className="modalspanbox headtwocb2 mt-3">
                    คืนยอดเสีย <span className='cashbacknb'>{cashback?.cachback} %</span><br/>
                    {(cashback?.textcashback)}
                </div>
                <h3 className="cashbacknb">
                {addCommas(parseFloat(cashback?.sumwinloss || 0).toFixed(2))}
                </h3>
                <h1 className="headtwocb">CASHBACK ที่ได้รับ</h1>
                <h5 className="cashbacknb">
                {addCommas(parseFloat(cashback?.money || 0).toFixed(2))} บาท
                </h5>
                {
                    (parseFloat(cashback?.money) > parseFloat(cashback?.mincachback) && (cashback?.payout) === 'ok') && <div>
                            {
                                 btnLoading === true ? <button type="button" className="btnLogin my-3">
                                 <span>กำลังเติมเครดิต</span>
                             </button> : <button type="button" onClick={()=>onSubmit()} className="btnLogin my-3">
                                 <span>รับยอด CASHBACK</span>
                             </button>
                             
                            }
                    </div>
                }

                                    {
                                        cashback?.payout === 'ok' && parseFloat(cashback?.paymentday) === 1 && <button type="button" className="btnAffrecive my-3">
                                        <span>วันนี้ คุณรับยอดเงินแล้ว</span>
                                    </button>
                                    }
                
                <div className="x-hr-border-glow mb-3"/>
                <div className="modalspanbox mt-3 text-start">
                เงื่อนไข<br />
คำนวนจากยอดเสีย<br />
✅ นับวันที่ 1-15 จ่ายวันที่ 16 และ นับจากวันที่ 16 ถึงสิ้นเดือน จ่ายวันที่1 (หากท่านมียอดเสียในระยะเวลาที่แจ้ง แต่ท่านไม่แจ้งรับ ในวันที่แจ้งจ่ายของเว็ป ยอดเสียของท่านจะถูกตัดทิ้งและเริ่มคำนวนใหม่ในรอบถัดไปทันที)<br />
✅ จ่ายคืนยอดเสีย ขั้นต่ำ {addCommas(parseFloat(cashback?.mincachback || 0).toFixed(0))} บาท<br />
✅ จ่ายคืนยอดเสียไม่เกิน {addCommas(parseFloat(cashback?.maxcachback || 0).toFixed(0))} บาท ต่อเดือน<br />
✅ ขั้นตอนรับคืนยอดเสีย ท่านสามารถกดปุ่ม <span className="cashbacknb1">“รับยอด CASHBACK”</span> <br />
✅ ระบบจะเติมยอด CASHBACK เข้ากระเป๋าเครดิตลูกค้า<br/>
✅ คำนวณยอดเสีย {cashback?.fulldate} รับเงินคืนวันที่ <span className='cashbacknb1'> {cashback?.payoutday}</span> วันเดียวเท่านั้น
                   
             
                </div>
                 
               
            </div>
            <ModelApprove show={cashbackalert} onHide={()=>{ dispatch(closecashbackalert({}))}} />
            <ModalLoading show={isLoading} onHide={()=>{}} />
        </div>
    );
}

export default CashBack;
