import  { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import {useSelector} from "react-redux";
import { checkcurrency } from '../config/Index'
import { LG } from '../config/Index'
const lgtext = {
    thai:{
      1:'ข้อมูลส่วนตัว',
      2:'ธนาคาร',
      3:'ชื่อบัญชี',
      4:'เลขที่บัญชี',
      5:'สกุลเงิน',
      6:'ยูสเซอร์',
      7:'เบอร์มือถือ',
      8:'โปรโมชั่น',
      9:'ยอดเงินคงเหลือ',
    },
    lao:{
      1:'ຂໍ້ມູນສ່ວນໂຕ',
      2:'ທະນາຄານ',
      3:'ຊື່ບັນຊີ',
      4:'ເລກບັນຊີ',
      5:'ສະກຸນເງິນ',
      6:'ຍຸສເຊີ້',
      7:'ເບີໂທ',
      8:'ໂປໂມຊັ້ນ',
      9:'ຍອດເງິນປະຈຸບັນ',
    }
  }
function Profile() {
    const user = useSelector(state => state.user)
    const navigate = useNavigate()
    useEffect(()=> {
        if(!user.isLogged) {
            navigate('/')
        }
    }, [])
    return (
        <div>
            <div className="headsecion">
                <i className="fas fa-user-alt"/> {lgtext[LG]['1']}
            </div>
            <div className="ctpersonal trans">
                <div className="modalspanbox mt-3">
                    <img src={user?.item?.bankphoto} className="photobankqr" /><br/>
                    {lgtext[LG]['2']} : <span className="txtyellow2">{user?.item?.bankname}</span><br/>
                    {lgtext[LG]['3']} : <span className="txtyellow2">{user?.item?.accname}</span><br/>
                    {lgtext[LG]['4']} : <span className="txtyellow2">{user?.item?.accno}</span><br />
                    {lgtext[LG]['5']} : <span className="txtyellow2">{checkcurrency(user?.item?.currency)}</span>
                </div>
                <div className="x-hr-border-glow mb-1"/>
                
            </div>

            <div className="ctpersonal grid mt-4">
                <div className="boxpsl">
                    <span>{lgtext[LG]['6']}</span>
                    <input type="text" value={user?.item?.username}  disabled/>
                </div>
              
                <div className="boxpsl">
                    <span>{lgtext[LG]['7']}</span>
                    <input type="text" value={user?.item?.mobile} disabled/>
                </div>
                <div className="boxpsl">
                    <span>{lgtext[LG]['8']}</span>
                    <input type="text" value="-"  disabled/>
                </div>
              
                <div className="boxpsl">
                    <span>{lgtext[LG]['9']}</span>
                    <input type="text" value={user?.item?.balance}  disabled/>
                </div>
            </div>
        </div>
    );
}

export default Profile;
