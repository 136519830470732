import {Modal} from "react-bootstrap";
import {useSelector} from "react-redux";
import { addCommas, checkcurrency } from '../config/Index'
import { LG } from '../config/Index'

const lgtext = {
    lao:{
      1:'ຍອດຖອນຮັບການຢືນຢັນ',
      2:'ຍົກເລີກຖອນເງິນ',
      3:'ທະນາຄານ',
      4:'ບັນຊີ',
      5:'ຍອດຖອນ',
    },
    thai:{
      1:'ยอดถอนได้รับการยินยัน',
      2:'ยกเลิกถอนเงิน',
      3:'ธนาคาร',
      4:'บัญชี',
      5:'ยอดถอน',
    }
  }
function LoginModal(props) {
    const { accountwithdraw } = useSelector(state => state.user)
    const { status } = props
    return (
        <Modal {...props} centered className={'login-modal'}>
                <div className={'closeloginbtn'} onClick={props.onHide}>X</div>

            <Modal.Body className={'contentmodal py-4'}>
                <div className="text-center">
                    {
                        status == 3 ? <img src='/images/approve.png' style={{maxWidth:80}} />  : <img src='/images/reject.png' style={{maxWidth:60}} /> 
                    }
                
                </div>
                
                    {
                        status == 3 ? <div className="text-center txtgreenh"><span>{lgtext[LG]['1']}</span></div> : <div className="text-center txtgreenred"><span>{lgtext[LG]['2']}</span></div>
                    }
                
             
            <div className="ctpersonal grid mt-4 boxbankd">
          
                <div className="boxpsl txtgreen">
                    <span>{lgtext[LG]['3']}</span>
                    {accountwithdraw?.bankname} 
                </div>
                <div className="boxpsl txtgreen">
                    <span>{lgtext[LG]['4']}</span>
                    {accountwithdraw?.accno}/ {accountwithdraw?.accname} 
                </div>
                <div className="boxpsl txtgreen">
                    <span>{lgtext[LG]['5']}</span>
                    {addCommas(parseFloat(accountwithdraw?.amount).toFixed(0))} (THB)
                </div>
                {
                    LG == 'lao' &&  <div className="boxpsl txtgreen">
                    <span>ອັດຕາ</span>
                    {addCommas(parseFloat(accountwithdraw?.rate).toFixed(0))}
                </div>
                }
               {
                LG == 'lao' && <div className="boxpsl txtgreen">
                <span>ຮັບເງິນ</span>
                {accountwithdraw?.amountvalue} ({checkcurrency(accountwithdraw?.currency)})
            </div>
               }
                
            </div>
            </Modal.Body>
        </Modal>
    );
}

export default LoginModal;
