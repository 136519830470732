import {Modal} from "react-bootstrap";
import { LG } from '../config/Index'

const lgtext = {
    lao:{
      1:'เปลี่ยนรหัสผ่าน เรียบร้อย พร้อมล๊อกเอ้าออกจากระบบ',
    },
    thai:{
      1:'เปลี่ยนรหัสผ่าน เรียบร้อย พร้อมล๊อกเอ้าออกจากระบบ',
    }
  }
function LoginModal(props) {
   
    return (
        <Modal {...props} centered className={'login-modal'}>
           <div className={'closeloginbtn'} onClick={props.onHide}>X</div>
            <Modal.Body className={'contentmodal py-4'}>
           
           <div className="text-center">
           <img src='/images/approve.png' style={{maxWidth:80}} />
           <div className="text-info">{lgtext[LG]['1']}</div>
           </div>
            </Modal.Body>
        </Modal>
    );
}

export default LoginModal;
