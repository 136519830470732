import {useState, useEffect} from "react";
import { useDispatch, useSelector} from 'react-redux'
import { gethistory } from '../store/user'
import { addCommas } from '../config/Index'
import ModalLoading from '../components/ModalLoading'
import { LG } from '../config/Index'
import { Pagination, Spin } from 'antd';
const lgtext = {
    thai:{
      1:'ประวัติ',
      2:'ทั้งหมด',
      3:'ฝาก/ถอน',
      4:'ถอน',
      5:'เดิมพัน',
      6:'คงเหลือ',
      7:'ไม่มีข้อมูล',
    },
    lao:{
      1:'ປະຫວັດ',
      2:'ທັງໝົດ',
      3:'ຝາກ/ຖອນ',
      4:'ຖອນ',
      5:'ເດີມພັນ',
      6:'ຍັງເຫຼືອ',
      7:'ບໍ່ມີຂໍ້ມູນ',
    }
  }
function History() {
    const dispatch = useDispatch()
    const [type, setType] = useState('bet');
    const [current, setCurrent] = useState(1);
    const onChange = (page) => {
        setCurrent(page);
        if(type == 'bet') {
            dispatch(gethistory({page, tab:type}))
        } else {
            dispatch(gethistory({page, tab:type}))
        }
      };
    const user = useSelector(state => state.user)
    const { meta, isLoadinghis } = user
    const { total } = meta
    const getMenu = (menu) => {
        setType(menu)
        if(menu === 'bet'){
            dispatch(gethistory({tab:menu}))
        } else {
            dispatch(gethistory({tab:menu}))
        }
        
    }

    useEffect(()=> {
        dispatch(gethistory({tab:'bet'}))
    }, [])
    const [transactions] = useState([
        {
            status: "อนุมัติ",
            type: "ถอน",
            account_no: "123-2-12312-1",
            amount: "250,000",
            bank_name: "ธนาคารไทยพาณิชย์",
            date: "15/02/2021 05:50:34"
        },
        {
            status: "ไม่อนุมัติ",
            type: "ฝาก",
            account_no: "123-2-12312-1",
            amount: "250,000",
            bank_name: "ธนาคารไทยพาณิชย์",
            date: "15/02/2021 05:50:34"
        }
    ]);

    
    return (
        <div>
            <div className="headsecion">
                <i className="far fa-history"/>  {lgtext[LG]['1']}
            </div>
            
            <div className="ctpersonal trans boxshw history">
                <div className="row mt-3">
                    <div className="col-2 p-0 leftdps">
                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                             aria-orientation="vertical">
                                {
                                    /*
  <a className={`nav-link text-white ${type === 'all' ? "active show" : ""}`}
                               onClick={() => getMenu('all')}>
                                 {lgtext[LG]['2']}
                            </a>
                                    */
                                }
                            <a className={`nav-link text-white ${type === 'bet' ? "active show" : ""}`}
                               onClick={() => getMenu('bet')}>
                                 {lgtext[LG]['5']}
                            </a>

                            <a className={`nav-link text-white ${type === 'Deposit' ? "active show" : ""}`}
                               onClick={() => getMenu('Deposit')}>
                                 {lgtext[LG]['3']}
                            </a>

                          
                        </div>
                    </div>

                    <div className="col-10 p-0">
                        <div className="tab-content">
                        <div class="d-flex justify-content-around">
                            {
                                isLoadinghis ? <Spin /> : <Pagination current={current} onChange={onChange} total={total} />
                            }
                        
                        </div>
                        <div className="text-center d-flex">
            
            </div>
                         


                            <div className={`tab-pane fade ${type === 'Depositss' ? "active show" : ""}`}>
                                <div className="griddps p-3">

                                    {transactions.map((transaction, index) => {
                                        return <div key={`deposit-${index}`}
                                                    className={`ingriddps ${transaction.type === 'ถอน' ? 'item-withdraw' : ""} ${transaction.type === 'ฝาก' ? 'item-deposit' : ""}`}>
                                            <div className={'d-flex justify-content-between'}>
                                                <div>
                                                <span
                                                    className={`badge rounded-pill  ${transaction.status === 'อนุมัติ' ? 'bg-success' : ""} ${transaction.status === 'ไม่อนุมัติ' ? 'bg-danger' : ""}`}>
                                                    <i className={`fas ${transaction.status === 'อนุมัติ' ? 'fa-check-circle' : ""} ${transaction.status === 'ไม่อนุมัติ' ? 'fa-times-circle' : ""}`}/>
                                                    {transaction.status}
                                                </span>
                                                </div>
                                                <div> {transaction.type}</div>
                                            </div>

                                            <div className={'d-flex justify-content-between'}>
                                                <div> {transaction.account_no}</div>
                                                <div> {transaction.amount}</div>
                                            </div>

                                            <div className={'d-flex justify-content-between small'}>
                                                <div> {transaction.bank_name}</div>
                                                <div> {transaction.date}</div>
                                            </div>
                                        </div>
                                    })}

                                </div>
                            </div>

                            <div className={`tab-pane fade ${type === 'xWithdraw' ? "active show" : ""}`}>
                                <div className="griddps p-3">

                                    {transactions.map((transaction, index) => {
                                        return <div key={`withdraw-${index}`}
                                                    className={`ingriddps ${transaction.type === 'ถอน' ? 'item-withdraw' : ""} ${transaction.type === 'ฝาก' ? 'item-deposit' : ""}`}>
                                            <div className={'d-flex justify-content-between'}>
                                                <div>
                                                <span
                                                    className={`badge rounded-pill  ${transaction.status === 'อนุมัติ' ? 'bg-success' : ""} ${transaction.status === 'ไม่อนุมัติ' ? 'bg-danger' : ""}`}>
                                                    <i className={`fas ${transaction.status === 'อนุมัติ' ? 'fa-check-circle' : ""} ${transaction.status === 'ไม่อนุมัติ' ? 'fa-times-circle' : ""}`}/>
                                                    {transaction.status}
                                                </span>
                                                </div>
                                                <div> {transaction.type}</div>
                                            </div>

                                            <div className={'d-flex justify-content-between'}>
                                                <div> {transaction.account_no}</div>
                                                <div> {transaction.amount}</div>
                                            </div>

                                            <div className={'d-flex justify-content-between small'}>
                                                <div> {transaction.bank_name}</div>
                                                <div> {transaction.date}</div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>

                            <div className={`tab-pane fade ${type === 'all' ? "active show" : ""}`}>
                                <div className="griddps p-3">
                                
                                    {user?.betitems && user?.betitems.map((bet, index) => {
                                        return <div className="ingriddps game-history" key={`bet-${index}`}>
                                            <div className={'d-flex justify-content-between'}>
                                                <div>
  
                                                    {
                                                        /*
 <span className="badge rounded-pill bg-success">
                                                    เลขอ้างอิง {bet.betid}
                                                </span>
                                                        */
                                                    }
                                               
                                                </div>
                                                <div>
                                               
                                                <span
                                                    className={`badge rounded-pill  ${bet.amount > 0 ? 'bg-success' : "bg-success"} ${bet.typeid == 1 ? 'bg-success' : "bg-danger"}`}>
                                                    <i className={`fas ${bet.status === 'อนุมัติ' ? 'fa-check-circle' : "bg-success"} ${bet.status === 'ไม่อนุมัติ' ? 'fa-times-circle' : "bg-danger"}`}/>
                                                    {bet.typename}
                                                </span>
                                               
                                                </div>
                                            </div>

                                            <div className={'d-flex justify-content-between'}>
                                                <div> {bet?.gpid}</div>
                                                <div> {bet.amount} THB</div>
                                            </div>

                                            <div className={'d-flex justify-content-between small'}>
                                            <div className={'small'}> <span>{bet.createdAt}</span></div>
                                                <div className="txtbalance">
                                                {lgtext[LG]['6']} {addCommas(parseFloat(bet.balance).toFixed(2))} THB
                                                </div>
                                                   
                                            </div>
                                            <div className={'d-flex justify-content-between'}>
                                            <button className="btnmor">รายละเอียด</button>
                                            </div>
                                            
                                        </div>
                                    })}
                                </div>
                            </div>

                            <div className={`tab-pane fade ${type === 'Deposit' ? "active show" : ""}`}>
                                <div className="griddps p-3">
                                {user?.deposititems && user?.deposititems.length == 0 && <div className="text-center">{lgtext[LG]['7']}</div>}
                                    {user?.deposititems && user?.deposititems.map((bet, index) => {
                                        return <div className={`ingriddps game-history ${(bet.type == 1 || bet.type == 3) ? 'bg-success' : 'bg-danger'}`} key={`bet-${index}`}>
                                            <div className={'d-flex justify-content-between'}>
                                                <div>
                                                    {
                                                        /*
 <span className="badge rounded-pill bg-success">
                                                    เลขอ้างอิง {bet.betid}
                                                </span>
                                                        */
                                                    }
                                               
                                                </div>
                                                <div>
                                                <span
                                                    className={`badge rounded-pill  ${bet.status == 3 ? 'bg-success' : "bg-danger"}`}>
                                                    <i className={`fas ${bet.status === 'อนุมัติ' ? 'fa-check-circle' : "bg-success"} ${bet.status === 'ไม่อนุมัติ' ? 'fa-times-circle' : "bg-danger"}`}/>
                                                    {bet.typename}
                                                </span>
                                                </div>
                                            </div>

                                            <div className={'d-flex justify-content-between'}>
                                                <div> 
                                                {bet?.typedeposit}
                                                </div>
                                                <div> {addCommas(parseFloat(bet.amount).toFixed(0))}</div>
                                            </div>

                                            <div className={'d-flex justify-content-between small'}>
                                            <div className={'small'}> <span>{bet.createdAt}</span></div>
                                                <div className="txtbalance">
                                                {lgtext[LG]['6']} {addCommas(parseFloat(bet.balance).toFixed(0))}
                                                </div>
                                             
                                            </div>

                                            
                                        </div>
                                    })}
                                </div>
                            </div>

                            <div className={`tab-pane fade ${type === 'Withdraw' ? "active show" : ""}`}>
                                <div className="griddps p-3">
                                    {user?.betitems && user?.betitems.filter(x=>x.type === 'Withdraw').length == 0 && <div className="text-center">{lgtext[LG]['7']}</div>}
                                    {user?.betitems && user?.betitems.filter(x=>x.type === 'Withdraw').map((bet, index) => {
                                        return <div className="ingriddps game-history" key={`bet-${index}`}>
                                            <div className={'d-flex justify-content-between'}>
                                                <div>
                                                    {
                                                        /*
 <span className="badge rounded-pill bg-success">
                                                    เลขอ้างอิง {bet.betid}
                                                </span>
                                                        */
                                                    }
                                               
                                                </div>
                                                <div>
                                                <span
                                                    className={`badge rounded-pill  ${bet.amount > 0 ? 'bg-success' : "bg-success"} ${bet.typeid == 1 ? 'bg-success' : "bg-danger"}`}>
                                                    <i className={`fas ${bet.status === 'อนุมัติ' ? 'fa-check-circle' : "bg-success"} ${bet.status === 'ไม่อนุมัติ' ? 'fa-times-circle' : "bg-danger"}`}/>
                                                    ถอนเงิน
                                                </span>
                                                </div>
                                            </div>

                                            <div className={'d-flex justify-content-between'}>
                                                <div> {bet?.gpid}</div>
                                                <div> {bet.amount} THB</div>
                                            </div>

                                            <div className={'d-flex justify-content-between small'}>
                                            <div className={'small'}> <span>{bet.createdAt}</span></div>
                                                <div className="txtbalance">
                                                {lgtext[LG]['6']} {addCommas(parseFloat(bet.balance).toFixed(2))} THB
                                                </div>
                                             
                                            </div>

                                            
                                        </div>
                                    })}
                                </div>
                            </div>

                            <div className={`tab-pane fade ${type === 'bet' ? "active show" : ""}`}>
                           
                                <div className="griddps p-3">
                               
                                    {user?.betitems && user?.betitems.filter(x=>x.type !== 'Deposit' && x.type !== 'Withdraw').length == 0 && <div className="text-center">{lgtext[LG]['7']}</div>}
                                    {user?.betitems && user?.betitems.filter(x=>x.type !== 'Deposit' && x.type !== 'Withdraw').map((bet, index) => {
                                        return <div className={`ingriddps game-history`} key={`bet-${index}`}>
                                            <div className={'d-flex justify-content-between'}>
                                                <div>
                                                    {
                                                        /*
 <span className="badge rounded-pill bg-success">
                                                    เลขอ้างอิง {bet.betid}
                                                </span>
                                                        */
                                                    }
                                               
                                                </div>
                                                <div>
                                                <span
className={`badge rounded-pill  ${bet.winloss_real > 0 && 'bg-success'} ${bet.winloss_real < 0 && bet.status!=='running' && 'bg-danger'} ${bet.winloss_real < 0 && bet.status==='running' && 'bg-warning'} ${bet.winloss_real == 0 && 'bg-info'}`}>
                                                    <i className={`fas ${bet.status === 'อนุมัติ' ? 'fa-check-circle' : "bg-success"} ${bet.status === 'ไม่อนุมัติ' ? 'fa-times-circle' : "bg-danger"}`}/>
                                                    {bet.typename} {bet.winloss_real !==0 && bet.status!=='running' && bet.winloss_real}
                                                </span>
                                                </div>
                                            </div>

                                            <div className={'d-flex justify-content-between'}>
                                                <div> {bet?.gpid}</div>
                                                <div>เดิมพัน <span style={{color:'yellow'}}>{bet.amount}</span></div>
                                            </div>

                                            <div className={'d-flex justify-content-between small'}>
                                            <div className={'small'}> <span>{bet.createdAt}</span></div>
                                                <div className="txtbalance">
                                                {lgtext[LG]['6']} {addCommas(parseFloat(bet.balance).toFixed(2))}
                                                </div>
                                             
                                            </div>

                                            
                                        </div>
                                    })}
                                </div>
                            </div>

                            {
                                // Pagination
                            }
                              
                            

                        </div>
                    </div>


                </div>
            </div>
            <ModalLoading show={user?.isLoading} onHide={()=>{}} />
        </div>
    );
}

export default History;
