import React from "react";

import { useSelector } from 'react-redux'
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function SwiperBanner() {
  

    const {banneritemspup} = useSelector(state => state.user);

    return (
        <>
        {
            banneritemspup && banneritemspup.length>0 && <div>
            <img className="w-100 swiper-image"
                 src={`https://apiadminbetg.com/images/banner/${banneritemspup[0].photo}`}
            alt={banneritemspup[0].photo}/>
        </div>
        }
        </>
    );
}
