import React, {useState, useEffect, useMemo, useRef} from "react";
import { useSelector, useDispatch} from "react-redux";
import { useNavigate, useParams } from 'react-router-dom'
import { opengame, gamedetail } from '../store/user'
import { APIURL } from "../config/Index";
import ModalLoad from './Modal'
import axios from 'axios'
function Partners() {
    const [menu, setMenu] = useState('sport');
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [gameid, setGameid] = useState("")
    const btnclick = useRef()
    const [fkprovider, setfkprovider] = useState("")
    const gameurl = async (fkprovider, {gameid}) => {
      
        if(user.isLogged) {
           
            if(fkprovider === '64ec4dcf7035219e4eb83502') {
                dispatch(opengame({fkprovider, gameid}))
               // const handleOpenNewWindow = () => {
                /*
                    const newPage = window.open('', '_blank');
                    newPage.document.open();
                    newPage.document.write('<h1>xxxx</h1>');
                    newPage.document.close();
                */
               //   };
                // pg
                setGameid(gameid)
                //setfkprovider(fkprovider)
            } else {
                dispatch(opengame({fkprovider, gameid}))
            }
            //
        } else {
            navigate(`/login`)
        }
        
    }

    const { provider } = useParams()
    //const dispatch = useDispatch()
    
    useEffect(()=> {
        dispatch(gamedetail({brandcode:provider}))
    } ,[provider])
    const { htmlall } = user;
    useMemo(()=>{
       
        if(htmlall.length > 1) {
            
            const newPage = window
            newPage.document.write(htmlall);
        
        }
        
    },[htmlall])


        return (
            <>
            
            <div className="text-center"><button onClick={()=>navigate(`/`)} className="btnback">กลับ</button></div>
            <div className="text-center"><img className={'img-fluid'} style={{maxHeight:125}} src={`${APIURL}/images/brand/${user?.headphoto}`} alt={'logo'}/></div>
          
            <div className={'gold-line'}/>
            <div className={''}>
     
                <section className="section02">
                    <div className="contenttabsec02">
                        <div className="tabctsec02">
                            <div className="row m-0 ">
                            <div className="col px-1">
                                    <div className={`tablinks ${menu === 'sport' ? 'active' : ""} `}>
                                        <button onClick={() => setMenu('sport')}>
                                            <img src="/images/icon/sport.png"/>
                                            <span>ทั้งหมด</span>
                                        </button>
                                    </div>
                                </div>
                             
                                
                                <div className="col px-1">
                                    <div className={`tablinks ${menu === 'slot' ? 'active' : ""} `}>
                                        <button onClick={() => setMenu('slot')}>
                                            <img src="/images/icon/slot.png"/>
                                            <span>Slot</span>
                                        </button>
                                    </div>
                                </div>
                               
                                <div className="col px-1">
                                    <div className={`tablinks ${menu === 'dice' ? 'active' : ""} `}>
                                        <button onClick={() => setMenu('dice')}>
                                            <img src="/images/icon/dice.png"/>
                                            <span>เกมไพ่</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col px-1">
                                    <div className={`tablinks ${menu === 'fish' ? 'active' : ""} `}>
                                        <button onClick={() => setMenu('fish')}>
                                            <img src="/images/icon/fish.png"/>
                                            <span>ยิงปลา</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div className={'gold-line'}/>
    
                    <div className="contain02">
                        <div className="tabcontent" style={{display: menu === 'fish' ? "block" : "none"}}>
                            <div className="gridgame alone">
                                {user?.gamedetailitems && user?.gamedetailitems.filter(x=>x.type==4).map((partner, index) => {
                                   return <div key={`partner-${index}`}><div className="ingridgame">
                                   <div className="iningridgame" onClick={()=>gameurl(partner.fkprovider, partner)}>
                                       <img src={partner.image}/>
                                   </div>
                               </div>
                               <div className="text-center">{partner.name}</div>
                               </div>
                                })}
                            </div>
                        </div>
    
                        <div className="tabcontent" style={{display: menu === 'dice' ? "block" : "none"}}>
                            <div className="gridgame alone">
                                {user?.gamedetailitems && user?.gamedetailitems.filter(x=>x.type==5).map((partner, index) => {
                                  return <div key={`partner-${index}`}><div className="ingridgame">
                                  <div className="iningridgame" onClick={()=>gameurl(partner.fkprovider, partner)}>
                                      <img src={partner.image}/>
                                  </div>
                              </div>
                              <div className="text-center">{partner.name}</div>
                              </div>
                                })}
                            </div>
                        </div>
    
                        <div className="tabcontent" style={{display: menu === 'slot' ? "block" : "none"}}>
                            <div className="gridgame alone">
                                {user?.gamedetailitems && user?.gamedetailitems.filter(x=>x.type==3).map((partner, index) => {
                                   return <div key={`partner-${index}`}><div className="ingridgame">
                                   <div className="iningridgame" onClick={()=>gameurl(partner.fkprovider, partner)}>
                                       <img src={partner.image}/>
                                   </div>
                               </div>
                               <div className="text-center">{partner.name}</div>
                               </div>
                                })}
                            </div>
                        </div>
    
                        <div className="tabcontent" style={{display: menu === 'sport' ? "block" : "none"}}>
                            <div className="gridgame alone">
                                {user?.gamedetailitems && user?.gamedetailitems.map((partner, index) => {
                                    return <div key={`partner-${index}`}><div className="ingridgame">
                                        <div className="iningridgame" onClick={()=>gameurl(partner.fkprovider, partner)}>
                                            <img src={partner.image}/>
                                        </div>
                                    </div>
                                    <div className="text-center">{partner.name}</div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </section>
                <ModalLoad show={user?.isloadinggame} onHide={()=>{}}/>
            </div>
         </>
    
        );

   
}

export default Partners;
