import {Modal} from "react-bootstrap";

function LoginModal(props) {
    return (
        <Modal {...props} centered className={'login-modal'}>
            {
                /*<div className={'closeloginbtn'} onClick={props.onHide}>X</div>*/

            }
            <Modal.Body className={'contentmodal py-4'}>
            <div className="ring">LOADGAME
  <span className='span1'></span>
</div>
<div style={{height:200}}></div>
            </Modal.Body>
        </Modal>
    );
}

export default LoginModal;
