import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { getwebinfo } from '../store/user'
import { LG } from '../config/Index'

const lgtext = {
    thai:{
      1:'ติดต่อ สอบถาม',
      2:'เฟส',
      3:'โทร',
      4:'ไลน์',
      5:'ช่องทางการติดต่อ',
    },
    lao:{
      1:'ຕິດຕໍ່ ສອບຖາມ',
      2:'ເຟສບຸກ',
      3:'ວັອດແອັບ',
      4:'ລາຍ',
      5:'ຊ່ອງທາງການຕິດຕໍ່',
    }
  }
function Contact() {
    const [spinname, setSinname] = useState('circle')
    const getcode = () => {
       // const xx = Math.floor(Math.random() * 10000) + 1
       // console.log('xx',xx)
       /*
        10 = 3096
       */
        return 5096
    }
    const spingame = () => {
        setSinname('circle start-rotate')
        setTimeout(()=> {
            setSinname('circle start-rotate stop-rotate')
        } , getcode())
    }
    const dispatch = useDispatch()
    useEffect(()=> {
        dispatch(getwebinfo({}))
    } ,[])
    const { webinfo:contact } = useSelector(state => state.user)
    const [banners] = useState([
        {name: 'banner1', image: './images/promotion/pro0.png'},
        {name: 'banner2', image: './images/promotion/pro1.png'},
        {name: 'banner3', image: './images/promotion/pro2.png'},
        {name: 'banner4', image: './images/promotion/pro3.png'},
        {name: 'banner5', image: './images/promotion/pro4.png'}
    ]) || [];

    return (
        <div className="container2">
            <div className="arrow2"></div>
            <ul className={spinname}>
                <li>
                    <div className="text"
                        contentEditable='true'
                        spellCheck='false'
                    >
                        1
                    </div>
                </li>
                <li>
                    <div className="text"
                        contentEditable='true'
                        spellCheck='false'
                    >
                        2
                    </div>
                </li>
                <li>
                    <div className="text"
                        contentEditable='true'
                        spellCheck='false'
                    >
                        3
                    </div>
                </li>
                <li>
                    <div className="text"
                        contentEditable='true'
                        spellCheck='false'
                    >
                        4
                    </div>
                </li>
                <li>
                    <div className="text"
                        contentEditable='true'
                        spellCheck='false'
                    >
                        5
                    </div>
                </li>
                <li>
                    <div className="text"
                        contentEditable='true'
                        spellCheck='false'
                    >
                        6
                    </div>
                </li>
             
                <li>
                    <div className="text"
                        contentEditable='true'
                        spellCheck='false'
                    >
                        7
                    </div>
                </li>
                <li>
                    <div className="text"
                        contentEditable='true'
                        spellCheck='false'
                    >
                        8
                    </div>
                </li>
                <li>
                    <div className="text"
                        contentEditable='true'
                        spellCheck='false'
                    >
                        9
                    </div>
                </li>
                <li>
                    <div className="text"
                        contentEditable='true'
                        spellCheck='false'
                    >
                        10
                    </div>
                </li>
                <li>
                    <div className="text"
                        contentEditable='true'
                        spellCheck='false'
                    >
                        11
                    </div>
                </li>
                <li>
                    <div className="text"
                        contentEditable='true'
                        spellCheck='false'
                    >
                        12
                    </div>
                </li>
             
            </ul>
            <button onClick={()=>spingame()} className="btn-button">Spin</button>
        </div>
    );
}

export default Contact;
