import { useEffect, useState, useMemo, useRef } from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getwebinfo, bankdeposit, createqrcode, rejectqrcodepost, checkqrcode, confirmqrcode, promotionlist, setStep} from "../store/user";
import { APIURL, addCommas, checkcurrency, checkcurrencyamount } from '../config/Index'
import ModalLoad from '../components/ModalQr'
import ModalLoading from '../components/ModalLoading'
import ModelApprove from '../components/ModelApprove'
import Profice from './Pedding'
import Showqrcode from './Showqrcode'
import VedioMoal from '../components/ModelRegister'

function Deposit() {
    const [show, setshow] = useState(false)
    const dispatch = useDispatch()
    useEffect(()=> {
        dispatch(bankdeposit({}))
        dispatch(checkqrcode({}))
        dispatch(promotionlist({}))
        dispatch(getwebinfo({}))
    }, [])

    const user = useSelector(state => state.user)

    const [type, setType] = useState(2)
    const [bankid, setbankid] = useState("")
    const [runid, setrunid ] = useState(0)
    const [btnphoto, setbtnphoto] = useState("")
    const [currency, setcurrency] = useState([])
    const [bonus, setbonus] = useState("0")

    const setbankall = (currencycheck, fkbank) => {
        setcurrency(currencycheck)
        setbankid(fkbank)
        setrunid(1)
    }

    const setcurrencyall = (dataid) => {
        setbtnphoto(dataid)
        setrunid(2)
    }


    const [banks, setbank] = useState([]);

    const getbankname = (fkbank, type) => {
        const data = banks.find(x=>x.fkbank == fkbank)
        return data[type]
    }

    const removestep = (type) => {
        if(type == 1) {
            setrunid(0)
            setcheckamount(0)
            setbankid("")
            setcurrency([])
        }
        if(type == 2 && runid !== 0) {
            setrunid(1)
            setcheckamount(0)
        }
    }

    const [trueWallet, settrueWallet] = useState([]);

    useMemo(()=> {
        if(user?.statusbank === 3) {
            const itemsnew = user.bankitems.find(x=>x.banktype == 2)
            const fkbank = itemsnew.items[0].fkbank
            setbtnphoto(`kip-${fkbank}`)
            setbank(itemsnew.items)
        }
    }, [user?.statusbank])
    const txtamountd = useRef(null)
    const depositSubmit = () => {
        const amount = checkamount
        if(!amount){
            txtamountd.current.focus()
        }else{
            const data = {
                amount,
                bankdata:btnphoto,
                bonus:bonus
            }
            dispatch(createqrcode(data))
        }
    }

    const [checkamount, setcheckamount] = useState(0)
    const [amountvalue, setamountvalue] = useState(0)
    const setAmount =(amount)=> {
        setcheckamount(amount)

        const typecur = checkcurrency(btnphoto.split("-")[0])
        if(typecur === 'THB') {
            const rate  =user.currency.currency_kip
            const total = parseFloat(parseFloat(amount) * rate).toFixed(0)
            setamountvalue(total)
        }

        if(typecur === 'USD') {
            const rate  = user.currency.currency_usd
            const total = parseFloat(parseFloat(amount) * rate).toFixed(0)
            setamountvalue(total)
        }

        if(typecur === 'KIP') {
           
            setamountvalue(amount)
        }
       
    }

    const depositConfirm = () => {
        dispatch(confirmqrcode({sid:user?.accountdeposit?.sid}))
    }
    const depositReject = () => {
        dispatch(rejectqrcodepost({sid:user?.accountdeposit?.sid,
            photo_ex:user?.slipexten,
            photo:user?.slipphoto,
            status:5
        }))
    }
  
    return (
        <div>
            <div className="headsecion">
                <img src="/images/icon/deposit.png"/> ຝາກເງິນ
            </div>
           
            <div className="ctpersonal">
            
                <div className="smallcontain">
                
                    {
                     bankid &&  <div className={'row'}>
                    <div className={'col-auto'}>
                        <img className={'circlered'} src={`${APIURL}/images/bank/${bankid && getbankname(bankid, 'photo')}`}/>
                    </div>
                    <div className={'col-auto'}>
                  
                        <span className="text-secondary">{bankid && getbankname(bankid, 'name')}</span>
                    </div>
                </div>
                }
                 {
                runid == 2 && <div className={'row'}>
                <div className={'col-auto'}>
                    <img className={'circlered'} src={`/images/currency/${btnphoto && checkcurrency(btnphoto.split("-")[0])}.png`}/>
                </div>
                <div className={'col-auto'}>
                ສະກຸນເງິນ<br/>
                    <span className="text-secondary">({btnphoto && checkcurrency(btnphoto.split("-")[0])})</span>
                   
                </div>
            </div>
            }

                        {
                            user?.accountdeposit?.depositstep !== 4 && user?.accountdeposit?.status !== 2 && user?.accountdeposit?.depositstep !== 3 && user?.accountdeposit?.status !== 1 && <div className="row m-0 mt-4">

<div className="col-4 p-0 text-center">
                        <div className={`${runid > 0 ? `btnactive3` : `btnactive2`}`} onClick={()=>removestep(1)}>1. ເລືອກທະນາຄານ</div>
                    </div>
                    <div className="col-4 p-0 text-center">
                     <div className={`${runid > 1 ? `btnactive3` : `btnactive2`}`} onClick={()=>removestep(2)}>2. ເລືອກສະກຸນເງິນ</div>
                    </div>
                    <div className="col-4 p-0 text-center">
                    <div className={`${runid > 2 ? `btnactive3` : `btnactive2`}`}>3. ໃສ່ຈຳນວນເງິນ</div>
                    </div>
                                </div>
                    }
                    <div className="row m-0 mt-4">
                        
                 
                  
                     
                    {user?.accountdeposit?.depositstep !== 4 && user?.accountdeposit?.status !== 2 && user?.accountdeposit?.depositstep !== 3 && user?.accountdeposit?.status !== 1 && runid == 0 && <div className='text-center pt-3 pb-3'><span class="dot">1</span><div>ເລືອກທະນາຄານ</div></div> }
                    {user?.accountdeposit?.depositstep !== 4 && user?.accountdeposit?.status !== 2 && user?.accountdeposit?.depositstep !== 3 && user?.accountdeposit?.status !== 1 && runid == 1 && <div className='text-center pt-3 pb-3'><span class="dot">2</span><div>ເລືອກສະກຸນເງິນ</div></div> }
                    {user?.accountdeposit?.depositstep !== 4 && user?.accountdeposit?.status !== 2 && user?.accountdeposit?.depositstep !== 3 && user?.accountdeposit?.status !== 1 && runid == 2 && <div className='text-center pt-3 pb-0'><span class="dot">3</span><div>ແຈ້ງການຝາກເງິນ, ເລືອກຈຳນວນຕາມເມນູລຸ່ມນີ້ເທົ່ານັ້ນ.</div></div> }
                   
                   {
                    (user?.accountdeposit?.depositstep !== 4 && user?.accountdeposit?.status !== 2 && user?.accountdeposit?.depositstep !== 3 && user?.accountdeposit?.status !== 1) && runid == 0 && <div className='d-flex pt-1'>
                    
                    {banks.map(bank => {
                                             
                                             return <div className="col-4 p-0 text-center" key={`${bank.fkbank}`}>
                                                 <div className="iningriddps2">
                                                     <img src={`${APIURL}/images/bank/${bank.photo}`} className="photobankx"/>
                                                     <div>
                                                     {bank.name}</div><div>
                                                     <button onClick={() => setbankall(bank.currency, bank.fkbank)}>ເລືອກ</button>
                                                 
                                                     </div>
                                                 </div>
                                             </div>
                                         })}
                    </div>
                   }

{ runid == 0 && <div className='vedio1 text-center pt-3' onClick={()=>setshow(true)}><img src={`/images/howto/deposit.png`} height={90} /></div> }
                    
                    {runid == 1 &&  <>
                   
                    <div className='d-flex pt-3'>
                    
                    {currency.map(bank => {
                                             
                                             return <div className="col-4 p-0 text-center" key={`${bank.fkbank}`}>
                                                 <div className="iningriddps2">
                                                 <img src={`/images/currency/${checkcurrency(bank.currency)}.png`} className="photobankx"/>
                                                     <div>
                                                     {checkcurrency(bank.currency)}</div><div>
                                                     <button onClick={()=> setcurrencyall(`${bank.currency}-${bankid}`)}>ເລືອກ</button>
                                                 
                                                     </div>
                                                 </div>
                                             </div>
                                         })}
                    </div>
                    </>}
                   
                    
                        <div className=" p-0">
                            <div className="tab-content" id="v-pills-tabContent">

                                    {
                                        user?.accountdeposit?.depositstep == 1 && <div className={`tab-pane fade ${type === 2 ? "active show" : ""}`}
                                        id="v-pills-home" role="tabpanel"
                                        aria-labelledby="v-pills-home-tab">
                                       <div className="griddps">
                                       <div className="modalspanbox mt-3">
                 
                       </div>
             
                                       </div>
                                   </div>
                                    }
                                

                                <div className={`tab-pane fade ${type === 1 ? "active show" : ""}`}
                                     id="v-pills-profile" role="tabpanel"
                                     aria-labelledby="v-pills-profile-tab">
                                    <div className="griddps">
                                    <div className="modalspanbox mt-3">
                        <span>ເລືອກ U-Money</span><br/>
                        ພ້ອມກັບເລືອກສະກຸນເງິນເພື່ອຈະຂໍ QRCode
                    </div>
                                    {trueWallet.map(bank => {
                                            return <div className="ingriddps" key={`${bank.fkbank}`}>
                                                <div className="iningriddps">
                                                    <img src={`${APIURL}/images/bank/${bank.photo}`} className="photobankx"/>
                                                    <div>
                                                        {bank.name} <br/>
                                                        <div className='pb-2'>ສະກຸນເງິນ</div>
                                                            {
                                                                bank.currency.map((y, index)=> <span className='p-1' key={`${y.currency}${bank.fkbank}`}><span onClick={()=> setbtnphoto(`${y.currency}-${bank.fkbank}`)} className={`${btnphoto}` == `${y.currency}-${bank.fkbank}` ? 'btn5' : 'btn6'}>{y.currency}</span></span>)
                                                            }
                                                    </div>
                                                </div>
                                            </div>
                                        })}

                                    </div>
                                </div>
                                
                                {
                                    runid == 2 && user?.accountdeposit?.depositstep == 1 && <div className='p-1'>
                                       
                                
                                <div>
                                    {
                                        checkamount > 0 &&  <div className={'row'}>
                                        <div className={'col'}>
                                        ຈຳນວນຝາກເງິນ {checkcurrency(btnphoto.split("-")[0])}
                                        </div>
                                        <div className={'col-auto'}>
                                            {btnphoto && `ຮັບເຄດິດ LAK`}
                                        </div>
                                    </div>
                                    }
                                   {
                                    checkamount > 0 && <div className={'row'}>
                                    <div className={'col'}>
                                    <div className='moneyBtn4' >{addCommas(parseFloat(checkamount).toFixed(0))}</div>
                                   
                                    </div>
                                    <div className={'col'}>
                                        <div className='moneyBtn3' >{addCommas(parseFloat(amountvalue).toFixed(0))}</div>
                                    </div>
                                </div>
                                   }
                                   {
                                    checkamount > 0 && <div className='text-center'>ອັດ​ຕາ​ແລກ​ປ່ຽນ {checkcurrencyamount(btnphoto.split("-")[0], user?.currency)}<button type="button" onClick={depositSubmit} className="moneyBtn">ຢືນຢັນຮັບ ຄິວອາ</button></div>
                                   }
                                   {
                                    checkamount > 0 && <div className='text-center'><button type="button" onClick={()=>setcheckamount(0)} className="moneyBtn2">ຍົກເລີກ</button></div>
                                   }
                                
                                <div className={'row align-items-center'}>
                                           
                                    <div className={'col-auto'}>
                                        
                                    </div>
                                    <div className={'col p-2'}>
                                       
                                    </div>
                                    {
                                        checkamount === 0 && 
                                    
                                    <div className='txtdarh'>
                                    ຂໍຄິວອາເພື່ອໂອນດ່ວນ <br />
ລູກຄ້າກົດເລືອກຈຳນວນເງິນທີ່ຕ້ອງການຝາກໄດ້ເລີຍ
{btnphoto && checkcurrency(btnphoto.split("-")[0]) === 'KIP' && <div className="gridgame alone m-0 mb-4">
    <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(1000)}>1,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(2000)}>2,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(3000)}>3,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(5000)}>5,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(7000)}>7,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(8000)}>8,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(10000)}>10,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(20000)}>20,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(30000)}>30,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(50000)}>50,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(70000)}>70,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(80000)}>80,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(100000)}>100,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(200000)}>200,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(300000)}>300,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(500000)}>500,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(700000)}>700,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(800000)}>800,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(1000000)}>1,000,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(2000000)}>2,000,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(3000000)}>3,000,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(5000000)}>5,000,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(10000000)}>10,000,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(20000000)}>20,000,000</button>
        </div>
        </div>}

        {btnphoto && checkcurrency(btnphoto.split("-")[0]) === 'THB' && <div className="gridgame alone m-0 mb-4">
    <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(50)}>50</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(100)}>100</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(150)}>150</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(200)}>200</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(300)}>300</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(500)}>500</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(700)}>700</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(1000)}>1,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(1500)}>1,500</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(2000)}>2,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(2500)}>2,500</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(3000)}>3,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(5000)}>5,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(10000)}>10,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(15000)}>15,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(18000)}>18,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(20000)}>20,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(30000)}>30,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(40000)}>40,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(50000)}>50,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(100000)}>100,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(300000)}>300,000</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(500000)}>500,000</button>
        </div>
        </div>}

        {btnphoto && checkcurrency(btnphoto.split("-")[0]) === 'USD' && <div className="gridgame alone m-0 mb-4">
    <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(5)}>5</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(10)}>10</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(20)}>20</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(25)}>25</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(35)}>35</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(50)}>50</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(80)}>80</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(100)}>100</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(150)}>150</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(200)}>200</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(300)}>300</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(500)}>500</button>
        </div>
        <div className="ingridgame p-0 text-center">
        <button className='btnamount' onClick={()=> setAmount(1000)}>1,000</button>
        </div>
        </div>}

                                    </div>
                                    }
                                    
                                    {
                                        /*
 <div><span className='btnh5 m-1'>ໂບນັດຝາກ</span></div>
                                    <div className='pb-1 pt-3'>
                                    <label>
  {bonus == '0' ? <input type="radio" name="radio" checked className='me-2' /> : <input type="radio" name="radio" className='me-2' onClick={()=>setbonus('0')} />}
  ບໍ່ຮັບໂປ
</label>
</div>
                                    {
                                        user?.bonusitems && user.bonusitems.map(x=> <div key={x.id} className='pb-1'>
                                        <label>
      {`${bonus}` === `${x.id}` ? <input type="radio" checked name="radio" className='me-2' /> : <input onClick={()=> setbonus(x.id)} type="radio" name="radio" className='me-2' />}
      ໂບນັດ {x.pbonus}
    </label>
    </div>)
                                    }
                                        */
                                    }
                                   
                                   
                                </div>
                                </div>
                                {
                                    checkamount === 0 && <div className='text-center txtatm'>
                                    ລູກຄ້າຮັບໂປເຄດິດຟຮີ ຕິດຕໍ່ແອດມິນ<br /> 
                        ທີ່ເບີວັອດແອັບ {user?.webinfo?.whatappid}
                                    </div>
                                }
                                
                                            
                                <hr/>
                                
                              
                                    </div>
                                }
                                {
                                     user?.accountdeposit?.depositstep == 4 && user?.accountdeposit?.status == 2 && <Profice />
                                }

                                {
                                    user?.accountdeposit?.depositstep == 3 && user?.accountdeposit?.status == 1 && <Showqrcode depositConfirm={depositConfirm} depositReject={depositReject} />
                                }
                        
                       
                            </div>
                        </div>
                    </div>
                                {
                                    /*
 <div className="modalspanbox mt-5 pt-5">
                        <span>กรุณาโอนมาจากบัญชีที่ลงทะเบียนเท่านั้น</span><br/>
                        หากลูกค้าฝากเงินสด ผ่านตู้ หรือ ช่องทางอื่น
                        กรุณาติดต่อแอดมินทุกกรณี
                    </div>
                                    */
                                }
                   
                </div>

            </div>
            <ModalLoad show={user?.accountdeposit?.depositstep == 2 && user?.accountdeposit?.status == 0} onHide={()=>{}}/>
            <ModelApprove show={user?.accountdeposit?.checkfinish == 'finish'} status={user?.accountdeposit?.status} onHide={()=>{ dispatch(setStep({accountdeposit:{depositstep:1}}))}} />
            <ModalLoading show={user?.isLoadingLogin} onHide={()=>{}} />
            <VedioMoal show={show} filename='deposit' onHide={()=>setshow(false)}/>
        </div>
    );
}

export default Deposit;
