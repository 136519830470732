import {Outlet} from "react-router-dom"
import Footer from "../components/Footer";
//import NavBar from "../components/NavBar"; // Thai
import NavBar from "../components/NavBarLao"; // Lao

function DefaultLayout() {
    return (
       <>
            <NavBar/>
            <main>
                <Outlet/>
            </main>
            <Footer/>
        </>
    );
}

export default DefaultLayout;
