import {useSelector, useDispatch} from "react-redux";
import { LG } from '../config/Index'
import { useRef } from "react";
import { addCommas, checkcurrency, getBase64, decodeBase64Image } from '../config/Index'
import { setslipexten, setslipphoto } from "../store/user";

const lgtext = {
    lao:{
      1:'ທະນາຄານ',
      2:'ບັນຊີຝາກ',
      3:'ຈຳນວນເງິນ',
      4:'ສະຖານະ',
      5:'ອັດຕາ',
      6:'ລໍຖ້າການກວດສອບ',
    },
    thai:{
      1:'ธนาคาร',
      2:'บัญชีฝาก',
      3:'จำนวนเงิน',
      4:'สถานะ',
      5:'อัตรา',
      6:'รอตรวจสอบ',
    }
  }
function Profile() {
    const { accountdeposit } = useSelector(state => state.user)

     
  const btnPhoto = useRef(null)
  const dispatch = useDispatch()
    const { slipexten, slipphoto } = useSelector(state => state.user)
   
 const handleChangePhoto = (event) => {
    if (event.target.files[0]) {
  
      const path = event.target.files[0]
  
      getBase64(path, (result) => {
        const { data: dataimg, type } = decodeBase64Image(result)
  
        dispatch(setslipexten(`data:${type};base64`))
        dispatch(setslipphoto(dataimg))
  
      })
    }
  }


  const showPhoto = () => {
    btnPhoto.current.click()
  }
    return (
        <div>
            <div className="ctpersonal grid mt-4">
                <div className="boxpsl">
                    <span>{lgtext[LG]['1']}</span>
                    <input type="text" value={accountdeposit?.bankname}  disabled/>
                </div>
                <div className="boxpsl">
                    <span>{lgtext[LG]['2']}</span>
                    <input type="text" value={`${accountdeposit?.accno} / ${accountdeposit?.accname}`}  disabled/>
                </div>
              
                <div className="boxpsl">
                    <span>{lgtext[LG]['3']} ({checkcurrency(accountdeposit?.currency)})</span>
                    <input type="text" value={addCommas(parseFloat(accountdeposit?.amount).toFixed(0))}  disabled/>
                </div>
                {
                    LG == 'lao' &&  <div className="boxpsl">
                    <span>{lgtext[LG]['5']}</span>
                    <input type="text" value={addCommas(parseFloat(accountdeposit?.rate).toFixed(0))}  disabled/>
                </div>
                }
               
                {
                    LG == 'lao' &&  <div className="boxpsl">
                    <span>ຮັບເຄດິດ (THB)</span>
                    <input type="text" value={addCommas(parseFloat(accountdeposit?.amountvalue).toFixed(0))}  disabled/>
                </div>
                }
               
              
                <div className="boxpsl">
                    <span>{lgtext[LG]['4']}</span>
                    <input type="text" value={lgtext[LG]['6']} disabled/>
                </div>

               
                
            </div>
            {
                    LG !== 'lao' &&  <div className="text-center">
                   อัพโหลดสลิป กรณีเงินไม่เข้าภายใน 2 นาที <br />
                   <button type="button" className="moneyBtn" onClick={()=> showPhoto()} > อัพโหลดสลิป </button>
                </div>
                }

<div className='imghide'>
                          <div className='mb-0'>
                            <input
                              type='file'
                              id='exampleCustomFileBrowser'
                              name='customFile'
                              onChange={(e) => handleChangePhoto(e)}
                              className="btnphoto"
                              ref ={btnPhoto}
                              accept='.jpg, .png, .gif'
                            />
                          </div>
                        </div>
        </div>
    );
}

export default Profile;
