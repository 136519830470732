import React, {useState} from "react";
import { useSelector } from "react-redux";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {Autoplay, Navigation, Pagination} from "swiper";
import { APIURL } from "../config/Index";
export default function SwiperBanner() {
    const user = useSelector(state => state.user)
    // user44
    /*
    const [banners] = useState([
        {name: 'banner 1', image: './images/banner/01.png'},
        {name: 'banner 2', image: './images/banner/02.png'},
        {name: 'banner 3', image: './images/banner/03.png'},
     
    ]) || [];
    */

    const [banners] = useState([
        {name: 'banner 1', image: './images/user147/b01.jpeg'},
        {name: 'banner 1', image: './images/user147/b01.jpeg'},
      
     
    ]) || [];
    
    /*
    const [banners] = useState([
        {name: 'banner 1', image: './images/banner/01.jpg'},
        {name: 'banner 2', image: './images/banner/02.jpg'},
        {name: 'banner 3', image: './images/banner/03.jpg'},
     
    ]) || [];
    */
    return (
        <>
        {
            user.isloadingbanner == true ? <div className="text-center">Loading</div> :  <Swiper
            slidesPerView={1}
            spaceBetween={30}
            slidesPerGroup={1}
            loop={true}
            navigation={true}
            pagination={{
                dynamicBullets: true
            }}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            modules={[Pagination, Autoplay, Navigation]}
            style={{
                "--swiper-navigation-color": "white",
                "--swiper-navigation-size": "25px",
                "--swiper-pagination-color": "white",
                "--swiper-pagination-bullet-inactive-color": "white",
                "--swiper-pagination-bullet-inactive-opacity": 0.2,
                "--swiper-pagination-bullet-opacity": 1,
            }}
        >
            {
                user.banneritems && user.banneritems.map(banner => {
                    return <SwiperSlide key={banner.name}>
                        <img className="w-100 swiper-image" src={`${APIURL}/images/banner/${banner.photo}`}
                             alt={banner.name}/>
                    </SwiperSlide>
                })
            }
        </Swiper>
        }
           
            <div className="swiper-pagination"/>
        </>
    );
}
