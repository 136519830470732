
import {useSelector} from "react-redux";

import { addCommas } from '../config/Index'
import { LG } from '../config/Index.js'

const lgtext = {
    lao:{
      1:'ຖອນເງິນ',
      2:'ຍອດຖອນ',
      3:'ສະຖານະ',
      4:'ລໍຖ້າການໂອນ',
      5:'ຖ້າໂອນເຂົ້າຈັກໜ້ອຍເດີຈ້າວ',
      6:'ພາຍໃນ 5 ນາທີເງີນຢັງບໍ່ເຂົ້າຕິດຕໍ່ແອດມິນທີ່ເບີວ໋ອດແອບ  02097969121',
    },
    thai:{
      1:'ถอนเงิน',
      2:'ยอดเงินที่ถอน',
      3:'สถานะ',
      4:'กำลังโอน',
      5:'ทำโปรสำเร็จ',
      6:'คุณทำยอดครบตามโปรโมชั่น ดังนี้',
    }
  }
function Withdraw() {
   
    const user = useSelector(state => state.user)
   
    return (
        <div className="ctpersonal mb-5">
             <div className="modalspanbox">
                        <span>ยินดีด้วยค่ะ</span><br/>
                        คุณทำยอดครบตามโปรโมชั่นแล้ว โปรดติดต่อแอดมิน เพื่อปลดล็อคโปร
                    </div>
                <div className="inboxmain">
               

                    <div className='xxx'>
                                            <div className="text-center txtheadpro">สรุปยอดการเดิมพัน ช่วงรับโปรฯ</div>
                                            <div className="text-center txtheadpro2">{user?.accountwithdraw?.fulltime}</div>
                                            <div className="d-flex justify-content-between pt-2"><span className="txtleft">เทิร์นที่กำหนด</span> <span className="txtleft">{addCommas(parseFloat(user?.accountwithdraw?.turnover).toFixed(0))}</span></div>
                                            <div className="d-flex justify-content-between pt-2"><span className="txtleft">เทิร์นที่ทำได้</span> <span className="txtleft">{addCommas(parseFloat(user?.accountwithdraw?.sumstak).toFixed(0))}</span></div>
                                            <div className="d-flex justify-content-between pt-2"><span className="txtleft">กำไร/ขาดทุน</span> <span className="txtleft">{addCommas(parseFloat(user?.accountwithdraw?.sumwinloss).toFixed(0))}</span></div>
                                            <div className="d-flex justify-content-between pt-2"><span className="txtleft">ถอนได้สูงสุด</span> <span className="txtleft">{addCommas(parseFloat(user?.accountwithdraw?.maxwithdraw))}</span></div>
                                          
                                        </div>
              {
                parseFloat(user?.item?.balance) > parseFloat(user?.accountwithdraw?.maxwithdraw) && <div className='pt-2 text-danger text-center'>** สมาชิกทำยอดเงินได้มากกว่า จำนวนถอนสูงสุด (ต้องถูกดึงเครดิตกลับดังนี้ {user?.item?.balance} - {user?.accountwithdraw?.maxwithdraw} = {parseFloat(user?.item?.balance) - parseFloat(user?.accountwithdraw?.maxwithdraw)})</div>
              }
                   
                </div>
            </div>
    );
}

export default Withdraw;
