import React, { useState, useEffect } from "react";
import { getRemainingTimeUntilMstimestamp } from "./ConutdownTimerUntils";
import {useNavigate} from "react-router-dom";
import { useDispatch } from "react-redux";
//import { removeBooking } from '../redux/member'

import { LG } from '../config/Index'

const lgtext = {
    lao:{
      1:'ກະລຸນາໂອນພາຍໃນ',
      2:'ນາທີ',
    },
    thai:{
      1:'กรุณาโอนภายใน',
      2:'นาที'
    }
  }

const defaultRemainTime = {
  seconds: "00",
  minutes: "00",
  hours: "00",
  days: "00",
  end: 1,
};
const CountdownTimer = ({ countdownTimestampMs }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [remainingTime, setRemainingTime] = useState(defaultRemainTime);
  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainTime(countdownTimestampMs);
    }, 1000);
    return () => clearTimeout(intervalId);
  }, []);
  function updateRemainTime(countdown) {
    setRemainingTime(getRemainingTimeUntilMstimestamp(countdown));
  }
  //const { booking } = useSelector(state=>state.member)

  useEffect(()=>{
    if(remainingTime.days == "00" &&
    remainingTime.hours == "00" &&
    remainingTime.minutes == "00" &&
    remainingTime.seconds == "00" &&
    remainingTime.end == 2){
      //dispatch(removeBooking())
      navigate("/");
    }
  },[remainingTime.end])

 return (
  <>
      <div className="appcountdown">
        <div className="text-center">
          
         
        </div>
        {remainingTime.days != "00" && <span>{remainingTime.days}</span>}
        {remainingTime.days != "00" && <span>ที่</span>}
        {remainingTime.days == "00" &&
          remainingTime.hours == "00" &&
          remainingTime.minutes == "00" &&
          remainingTime.seconds == "00" &&
          remainingTime.end == 2 && <div className="text-center" style={{fontSize:20,color:'red'}}>หมดเวลา</div>}
  
        {remainingTime.end != 2 && (
          <>
          <div className="text-center attime" style={{fontSize:20}} >
          {lgtext[LG]['1']} : {remainingTime.minutes}:{remainingTime.seconds} {lgtext[LG]['2']}
          </div>
        
          </>
        )}
      </div>
      </>
    );
  
};

export default CountdownTimer;
