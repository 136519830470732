import React, {useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {Autoplay, FreeMode} from "swiper";

export default function SwiperPartner() {
    const [partners] = useState([
        {name: 'partner 1', image: './images/partner/dragoonsoft.a39781a.png'},
        {name: 'partner 2', image: './images/partner/dream-gaming.d993e67.png'},
        {name: 'partner 3', image: './images/partner/gamatron.96cdfab.png'},
        {name: 'partner 4', image: './images/partner/joker-gaming.417d073.png'},
        {name: 'partner 5', image: './images/partner/live-22.dfd4972.png'},
        {name: 'partner 6', image: './images/partner/pretty-gaming.f27ce57.png'},
        {name: 'partner 7', image: './images/partner/sa-gaming.52e386a.png'},
        {name: 'partner 8', image: './images/partner/sexy-gaming.09728c1.png'},
        {name: 'partner 9', image: './images/partner/spade-gaming.d835b4d.png'},
        {name: 'partner 10', image: './images/partner/ag-gaming.2eb0369.png'},
        {name: 'partner 11', image: './images/partner/ambbet.1d53780.png'},
        {name: 'partner 12', image: './images/partner/ameba.dea3fdf.png'},
    ]) || [];

    return (
        <div className={'swiper-partner'}>
            <div className={'container'}>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    slidesPerGroup={3}
                    loop={true}
                    pagination={{
                        dynamicBullets: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        350: {
                            slidesPerView: 4,
                            spaceBetween: 10,
                        },
                        540: {
                            slidesPerView: 4,
                            spaceBetween: 10,
                        },
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 10,
                        },
                        1024: {
                            slidesPerView: 6,
                            spaceBetween: 10,
                        },
                    }}
                    freeMode={true}
                    modules={[FreeMode, Autoplay]}
                    className="py-3 swiper-container-2"
                >

                    {partners.map(partner => {
                        return <SwiperSlide key={partner.name} style={{width: '100%'}}>
                            <img className="img-fluid img-partner" src={partner.image} alt={partner.name}/>
                        </SwiperSlide>
                    })}
                </Swiper>
            </div>
        </div>
    );
}
