import {Modal} from "react-bootstrap";
import { WEBNAME } from "../config/Index";

function LoginModal(props) {
    return (
        <Modal {...props} centered className={'login-modal'}>
      
                <div className={'closeloginbtn'} onClick={props.onHide}>X</div>

            <Modal.Body className={'contentmodal py-4'}>
            <video width={350} height={240} controls autoPlay> 
  <source src={`/images/${WEBNAME}/${props.filename}.mp4`} type="video/mp4" />
</video>
            </Modal.Body>
        </Modal>
    );
}

export default LoginModal;
