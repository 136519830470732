
import {useSelector} from "react-redux";

import { addCommas } from '../config/Index'
import { LG } from '../config/Index.js'

const lgtext = {
    lao:{
      1:'ຖອນເງິນ',
      2:'ຍອດຖອນ',
      3:'ສະຖານະ',
      4:'ລໍຖ້າການໂອນ',
      5:'ຖ້າໂອນເຂົ້າຈັກໜ້ອຍເດີຈ້າວ',
      6:'ພາຍໃນ 5 ນາທີເງີນຢັງບໍ່ເຂົ້າຕິດຕໍ່ແອດມິນທີ່ເບີວ໋ອດແອບ  02097969121',
    },
    thai:{
      1:'ถอนเงิน',
      2:'ยอดเงินที่ถอน',
      3:'สถานะ',
      4:'กำลังโอน',
      5:'ขออภัย',
      6:'ยังไม่สามารถถอนได้ กรุณาทำยอดให้ครบตามโปรโมชั่น',
    }
  }
function Withdraw({type}) {
   
    const user = useSelector(state => state.user)
   
    return (
        <div className="ctpersonal mb-5">
             <div className="modalspanbox">
                        <span>{lgtext[LG]['5']}</span><br/>
                       
                         {parseFloat(user?.amount) < 5 ? 'ไม่สามารถถอนได้ กรุณาติดต่อแอดมินเพื่อปลดล็อคโปรฯ' : <div>{ lgtext[LG]['6']}</div>}
          
                    </div>
                <div className="inboxmain">
                <div className='xxx'>
                                            <div className="text-center txtheadpro">เงื่อนไขการรับโปรฯ</div>
                                            <div className="d-flex justify-content-between pt-2"><span className="txtleft">1. ฝาก</span> <span className="txtleft">{addCommas(parseFloat(user?.accountwithdraw?.amount).toFixed(0))}</span></div>
                                            <div className="d-flex justify-content-between pt-2"><span className="txtleft">2. รับโนนัส</span> <span className="txtleft">{addCommas(parseFloat(user?.accountwithdraw?.bonus).toFixed(0))}</span></div>
                                            <div className="d-flex justify-content-between pt-2"><span className="txtleft">3. {user?.accountwithdraw?.pturntype}</span> <span className="txtleft">{addCommas(parseFloat(user?.accountwithdraw?.turnover).toFixed(0))}</span></div>
                                            <div className="d-flex justify-content-between pt-2"><span className="txtleft">4. ถอนได้สูงสุด</span> <span className="txtleft">{addCommas(parseFloat(user?.accountwithdraw?.maxwithdraw))}</span></div>
                                        </div>
                   
                    <hr/>

                    <div className='xxx'>
                                            <div className="text-center txtheadpro">ข้อมูลการเดิมพันของสมาชิก</div>
                                            <div className="text-center txtheadpro">{user?.accountwithdraw?.fulltime}</div>
                                            <div className="d-flex justify-content-between pt-2"><span className="txtleft">เทิร์นที่ทำได้</span> <span className="txtleft">{addCommas(parseFloat(user?.accountwithdraw?.sumstak).toFixed(0))}</span></div>
                                            <div className="d-flex justify-content-between pt-2"><span className="txtleft">กำไร/ขาดทุน</span> <span className="txtleft">{addCommas(parseFloat(user?.accountwithdraw?.sumwinloss).toFixed(0))}</span></div>
                                          
                                        </div>

                   
                </div>
            </div>
    );
}

export default Withdraw;
