import Logo from "./Logo";
import SwiperPartner from "./SwiperPartner";
import {useSelector} from "react-redux";
import { LG, WEBTOKEN ,WEBFULLNAME as WEBNAME } from '../config/Index'

const lgtext = {
    lao:{
      1:`${WEBNAME} ພະນັນບານເວປຕົງເຈົ້າທຳອິດໃນປະທດລາວ`,
      2:'ຊ່ອງທາງການຝາກ',
      3:'ແທງບານອອນລາຍ',
      4:`${WEBNAME}`,
      5:'ບານ',
      6:'ຮອງຮັບຍູມັນນີ້',
      7:'ເຮົາແມ່ນທີ່ໃຫ້ຫຼີ້ນແທງບານອອນລາຍໃນໂທລະສັບ ແລະ ໃນຄອມ ມີ ບາຄາລ້າ ເກມສະລ໋ອດ ເສືອ ມັງກອນ ມີໃຫ້ຫຼີ້ນຫຼາຍເກມ ສາມາດເລືອກຫຼີ້ນໄດ້ຫຼາຍຮູບແບບ  ເປັນປະສົບການຕໍ່ຜູ້ຫຼີ້ນ ທີ່ສະດວກ ແລະ ວ່ອງໃວ ຮອງຮັບການຫຼີ້ນໃນລະບົບໂທລະສັບທີ່ດີທີ່ສຸດ',
      8:`www.${WEBNAME}.com`
    },
    thai:{
      1:`${WEBNAME} เว็บเดิมพันออนไลน์อันดับ 1`,
      2:'ช่องทางการฝาก',
      3:'แทงบอลออนไลน์',
      4:`${WEBNAME}`,
      5:'บาคาร่า',
      6:'ฝากถอน รวดเร็ว มั่นคง',
      7:'เราคือที่ให้เล่นแทงบอลออนไลน์ในรูปแบบมือถือ และบนคอมพิวเตอร์ มี บาคาร่า เกมส์สล็อต เสือ มังกร มีให้เล่นมากในเกมส์ สามารถเลือกเล่นได้หลากหลายรูปแบบ ประสบการณ์ต่อผู้เล่น ที่สะดวก เสถียรที่สุด รองรับทั้งมือถือ',
      8:`WWW.${WEBNAME}.COM`
    }
  }
function Footer() {
    const user = useSelector(state => state.user)

    return (
        <div className={'footer mt-auto'}>
            {!user.isLogged && <>
                <SwiperPartner/>
                <div className={'container'}>
                    <div className={'row text-center text-sm-start'}>
                        <div className={'col-12 col-sm-12 col-md mb-3'}>
                            <Logo style={{maxHeight: '40px', marginBottom: '15px'}}/>
                            <h5 className={'mb-3'}>
                                
                                {lgtext[LG]['1']}
                            </h5>

                            <div className={'small mb-2'}>
                            {lgtext[LG]['2']}
                            </div>
                            {
                                LG == 'thai' ? <div className="bankcontainer">
                                <img src="./images/icon/kbank-150x150.png" alt={'Kbank'}/>
                                <img src="./images/icon/scb-150x150.png" alt={'SCB'}/>
                                <img src="./images/icon/ktb-150x150.png" alt={'KTB'}/>
                                <img src="./images/icon/bbl-150x150.png" alt={'BBL'}/>
                                <img src="./images/icon/bay-150x150.png" alt={'BAY'}/>
                                <img src="./images/icon/tbank-150x150.png" alt={'TBANK'}/>
                                <img src="./images/icon/gsb-150x150.png" alt={'GSB'}/>
                                <img src="./images/icon/uob-150x150.png" alt={'UOB'}/>
                            </div> : <div className="bankcontainer">
                                <img src="./images/icon/bcel2.png" alt={'BCEL'}/>
                                <img src="./images/icon/jdb.png" alt={'JDB'}/>
                                <img src="./images/icon/bankl2.png" alt={'bbl'}/>
                            </div>
                            }
                            
                        </div>

                        <div className={'col-12 col-md mb-3'}>
                            <div className={'mb-3'}>
                            <span className="tagfooter">
                                <div className="textwidget">
                                  <a href="/">{lgtext[LG]['3']}</a>
                                </div>
                            </span>

                                <span className="tagfooter">
                                <div className="textwidget">
                                  <a href="/">{lgtext[LG]['5']}</a>
                                </div>
                            </span>

                                <span className="tagfooter">
                                <div className="textwidget">
                                  <a href="/">{lgtext[LG]['4']}</a>
                                </div>
                            </span>
                            {
                                WEBTOKEN === 'Sbo95th' &&  <span className="tagfooter">
                                <div className="textwidget">
                                  <a href="/">คอมคิดนอก</a>
                                </div>
                            </span>
                            }
                           {
                            WEBTOKEN === 'Sbo95th' && <span className="tagfooter">
                            <div className="textwidget">
                              <a href="/">ฝากถอน ไม่ติดเทิร์น</a>
                            </div>
                        </span>
                           }
                            {
                                WEBTOKEN === 'Sbo95th' && <span className="tagfooter">
                                <div className="textwidget">
                                  <a href="/">Gclub Auto</a>
                                </div>
                            </span>
                            }
                            
                            {
                                WEBTOKEN === 'Sbo95th' && 
                            <span className="tagfooter">
                                <div className="textwidget">
                                  <a href="/">สล๊อตแตกง่าย</a>
                                </div>
                            </span>
                            }
                            </div>

                            <div>
                                <span className="headerbt">{lgtext[LG]['6']}</span>
                                {
                                    //LG == 'thai' ? <img className={'true-money'} src="./images/icon/truewallet.svg"/> : <img className={'true-money'} src="./images/icon/um.png"/>
                                    LG == 'thai' ? <img className={'true-money'} src="./images/icon/ic-consistency.png"/> : <img className={'true-money'} src="./images/icon/um.png"/>
                                }
                                
                            </div>

                        </div>

                        <div className={'col-12 col-md mb-3'}>
                            <Logo style={{maxHeight: '40px', marginBottom: '15px'}}/>
                            <h5>
                            {lgtext[LG]['4']}
                            </h5>
                            <div>
                            {lgtext[LG]['7']}
                            </div>


                        </div>
                    </div>
                </div>
            </>}

            <div className="copyright">
                COPYRIGHT©2023,  {lgtext[LG]['8']}
            </div>
        </div>
    );
}

export default Footer;
