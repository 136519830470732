import {useDispatch, useSelector} from "react-redux";
import { memberchangpass, logout} from "../store/user";
import { useNavigate} from "react-router-dom";
import { useRef} from "react";
import { LG } from '../config/Index'
import Modal from '../components/ModalPass'
const lgtext = {
    thai:{
      1:'เปลี่ยนรหัสผ่าน',
      2:'รหัสผ่านเดิม',
      3:'รหัสผ่านใหม่',
      4:'ยืนยันรหัสผ่าน',
      5:'รหัสผ่านเดิมไม่ถูกต้อง'
    },
    lao:{
      1:'ປ່ຽນລະຫັດຜ່ານ',
      2:'ລະຫັດຜ່ານເກົ່າ',
      3:'ລະຫັດຜ່ານໃໝ່',
      4:'ຍືນຍັນລະຫັດຜ່ານ',
      5:'ລະຫັດຜ່ານເກົ່າບໍ່ຖືກຕ້ອງ'
    }
  }
function ChangePassword() {
    const navigate = useNavigate();
    const user = useSelector(state => state.user)
    const dispatch = useDispatch();
    const txtpassword = useRef(null)
    const txtcfpassword = useRef(null)
    const txtcfpassword2 = useRef(null)

    const changpass =()=> {
        const password1 = txtcfpassword.current.value;
        const password2 = txtcfpassword2.current.value;
        const password = txtpassword.current.value;
        if(!password){
            txtpassword.current.focus()
        } else if(!password1 && password){
            txtcfpassword.current.focus()
        } else if(password1 !== password2){
            txtcfpassword2.current.focus()
        } else {
            const data = {
                password,
                newpassword:password2,
            }
            dispatch(memberchangpass(data));
        }
    }

    const signOut = async () => {
        await dispatch(logout());
        return navigate("/");
    }
    
    return (
        <div>
            <div className="headsecion">
                <i className="fas fa-user-lock"/> {lgtext[LG]['1']}
            </div>

            <div className="ctpersonal">
                <form className="mt-4" id="form2" name="form2" method="post">
                    <div className="boxpsl">
                        <span>{lgtext[LG]['2']}</span>
                        <input type="password" ref={txtpassword} name="password"/>
                    </div>
                    {
                                user?.errcode == 403 &&  <div className="mb-3 text-danger text-center">{lgtext[LG]['5']}</div>
                               }
                    <div className="boxpsl">
                        <span>{lgtext[LG]['3']}</span>
                        <input type="password" ref={txtcfpassword} name="newpassword"/>
                    </div>
                    <div className="boxpsl">
                        <span>{lgtext[LG]['4']}</span>
                        <input type="password" ref={txtcfpassword2} name="newpassword"/>
                    </div>

                    <a className="btnLogin" onClick={()=> changpass()} id="btnLogin">{lgtext[LG]['1']}</a>
                </form>
            </div>
            <Modal show={user?.errcode == 'changepass'} onHide={()=>signOut()} />
        </div>
    );
}

export default ChangePassword;
