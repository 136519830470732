import {Modal} from "react-bootstrap";
import { LG } from '../config/Index'

const lgtext = {
    lao:{
      1:'ຖ້າຮັບ QRcode ຈັກໜ້ອຍ',
      2:'ຖ້າລະບົບຢືນຢັນພາຍໃນ 1 ນາທີ ຖ້າຍັງບໍ່ມີຍອດເງິນເຂົ້າຕິດຕໍ່ແອດມິນໄດ້ເລີຍຈ້າວ',
    },
    thai:{
      1:'กรุณารอ Qrcode สักครู่',
      2:'ภายใน 1 นาทียังไม่ได้รับ กรุณาติดต่อแอดมิน'
    }
  }
function LoginModal(props) {
    return (
        <Modal {...props} centered className={'login-modal'}>
            {
                /*<div className={'closeloginbtn'} onClick={props.onHide}>X</div>*/

            }
            <Modal.Body className={'contentmodal py-4'}>
            <div className='text-center txtyellow'>{lgtext[LG]['1']}</div>
            <div className="ring">LOADING
  <span className='span1'></span>
</div>
<div style={{height:200}}></div>
<div className='text-center txtyellow'>{lgtext[LG]['2']}</div>
            </Modal.Body>
        </Modal>
    );
}

export default LoginModal;
