import Marquee from "react-fast-marquee";
import { LG, WEBNAME, WEBFULLNAME } from '../config/Index'

const lgtext = {
    lao:{
      1:`ຄາຊິໂນອອນລາຍ ສະລ໋ອດອອນລາຍ ທີ່ເຮັດໃຫ້ທ່ານມ່ວນໃນທຸກຮູບແບບ ${WEBFULLNAME}`,
    },
    thai:{
      1:`${WEBFULLNAME} หวยออนไลน์ คาสิโนออนไลน์ สล็อตออนไลน์ ที่ให้คุณสนุกได้ทุกรูปแบบ`,
    }
  }
function Announcement() {
    return (
        <div className={'newsboxhead'}>
            <Marquee className={'notice'} gradient={false} speed={100}>
            {lgtext[LG]['1']}
            </Marquee>
        </div>
    );
}

export default Announcement;
