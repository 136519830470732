import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LG, addCommas } from '../config/Index'
import { checkcommission, commissionwithdraw, closecommissionalert } from '../store/user';
import ModelApprove from '../components/ModalCashback'
const lgtext = {
    lao:{
      1:'ຄືນຍອດເສຍ',
      2:'ບໍ່ມີຂໍ້ມູນ',
    },
    thai:{
      1:'คอมมิชชั่น',
      2:'ไม่มีข้อมูลการเดิมพัน',
     
    }
  }
function CashBack() {
    const dispatch = useDispatch()
    const { commission, btnLoading, commissionalert } = useSelector(state => state.user )
    useEffect(()=> {
        dispatch(checkcommission({}))
    },[])

    const onSubmit = () => {
        if(btnLoading === false){
            dispatch(commissionwithdraw({}))
        }
    }
    return (
        <div>
            <div className="headsecion">
                <img src="images/icon/com.png"/>  {lgtext[LG]['1']}
            </div>

            <div className="ctpersonal trans text-center">
            {
            //lgtext[LG]['2']
           }
               
                      <div className="modalspanbox headtwocb2 mt-3">
                      คอมมิชชั่น <span className='cashbacknb'>{commission?.cachback} %</span><br/>
                    <span className='txtac'>{(commission?.textcommission)}</span>
                </div>
                <h3 className="cashbacknb">
                TurnOver : {addCommas(parseFloat(commission?.sumwinloss || 0).toFixed(2))}
                </h3>
                <h1 className="headtwocb">คอมมิชชั่น ที่ได้รับ</h1>
                <h5 className="cashbacknb">
                {addCommas(parseFloat(commission?.money || 0).toFixed(2))} บาท
                </h5>
                {
                    (parseFloat(commission?.money) > parseFloat(commission?.mincachback) && (commission?.payout) === 'ok') && <div>
                            {
                                 btnLoading === true ? <button type="button" className="btnLogin my-3">
                                 <span>กำลังเติมเครดิต</span>
                             </button> : <button type="button" onClick={()=>onSubmit()} className="btnLogin my-3">
                                 <span>รับยอด คอมมิชชั่น</span>
                             </button>
                             
                            }
                    </div>
                }

                                    {
                                        commission?.payout === 'ok' && parseFloat(commission?.paymentday) === 1 && <button type="button" className="btnAffrecive my-3">
                                        <span>วันนี้ คุณรับยอดเงินแล้ว</span>
                                    </button>
                                    }
                
                <div className="x-hr-border-glow mb-3"/>
                <div className="modalspanbox mt-3 text-start">
                เงื่อนไข<br />
คำนวนจากยอด TurnOver<br />
✅ นับวันที่ <span className='txtac'>1-15</span> จ่ายวันที่ 16 และ นับจากวันที่ 16 ถึงสิ้นเดือน จ่ายวันที่ <span className='txtac'>1</span> ( <span className='txtac'>หากท่านมียอดคอมมิชชั่นในระยะเวลาที่แจ้ง แต่ท่านไม่แจ้งรับ ในวันที่แจ้งจ่ายของเว็ป ยอดคอมมิชชั่นของท่านจะถูกตัดทิ้งและเริ่มคำนวนใหม่ในรอบถัดไปทันที</span>)<br />
✅ จ่ายคืนคอมมิชชั่น ขั้นต่ำ <span className='txtac'>{addCommas(parseFloat(commission?.mincachback || 0).toFixed(0))} บาท</span><br />
✅ จ่ายคืนคอมมิชชั่นไม่เกิน <span className='txtac'>{addCommas(parseFloat(commission?.maxcachback || 0).toFixed(0))} บาท</span> ต่อรอบ  <span className='txtac'>15 วัน</span><br />
✅ ขั้นตอนรับคืนค่าคอมมิชชั่น ท่านสามารถกดปุ่ม <span className="cashbacknb1">“รับยอด คอมมิชชั่น</span> <br />
✅ ระบบจะเติมยอด คอมมิชชั่น เข้ากระเป๋าเครดิตลูกค้า<br/>
✅ คำนวณยอด คอมมิชชั่น {commission?.fulldate} รับเงินคืนวันที่ <span className='cashbacknb1'> {commission?.payoutday}</span> วันเดียวเท่านั้น
                   
             
                </div>
                 
               
            </div>
            <ModelApprove show={commissionalert} onHide={()=>{ dispatch(closecommissionalert({}))}} />
        </div>
    );
}

export default CashBack;
