import Logo from "../components/Logo";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { memberlogin} from "../store/user";
import { useRef, useEffect} from "react";
import { WEBTOKEN, LG } from '../config/Index.js'

const lgtext = {
    lao:{
      1:'ສະໝັກສະມາຊິກ',
      2:'ຍຸສເຊີ້',
      3:'ລະຫັດຜ່ານ',
      4:'ຢືນຢັນລະຫັດຜ່ານ',
      5:'ໃສ່ຂໍ້ມູນທະນາຄານ',
      6:'ໃສ່ຊື່ບັນຊີ',
      7:'ໃສ່ເລກບັນຊີ (ບໍ່ໃສ່ -)',
      8:'ໃສ່ຂໍ້ມູນສະກຸນເງິນ',
      9:'ໃສ່ເບີໂທ',
      10:'ຫຼືທ່ານມີບັນຊີຢູ່ແລ້ວ?',
      11:'ເຂົ້າສູ່ລະບົບ',
      12:'ຍັງບໍ່ທັນເປັນສະມາຊິກ?',
      13:'ບໍ່ພົບຂໍ້ມູນສະມາຊິກ ກະລຸນາປ້ອນໃໝ່ອີກເທື່ອ',
    },
    thai:{
      1:'สมัครสมาชิก',
      2:'ยูสเซอร์เนม',
      3:'รหัสผ่าน',
      4:'ยืนยันรหัสผ่าน',
      5:'ใส่ข้อมูลธนาคาร',
      6:'ชื่อบัญชี',
      7:'เลขบัญชี',
      8:'สกุลเงิน',
      9:'เบอร์โทร',
      10:'ท่านมีบัญชีอยู่แล้ว ?',
      11:'เข้าสู่ระบบ',
      12:'ยังไม่เป็นสมาชิก ?',
      13:'ไม่พบข้อมูลสมาชิก กรุณาป้อนใหม่อีกครั้ง',
    }
  }
function Login() {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /*
    const signIn = async () => {
        await dispatch(login());
        return navigate("/");
    }
    */

    useEffect(()=> {
        if(localStorage.getItem('access_token')) {
            return navigate("/");
        }
    }, [])
    const txtusername = useRef(null)
    const txtpassword = useRef(null)

    const loginpage = () => {
        const username = txtusername.current.value;
        const password = txtpassword.current.value;
        if(!username){
            txtusername.current.focus()
        } else if(!password && username){
            txtpassword.current.focus()
        } else {
            const data = {
                username,
                password,
                WEBTOKEN
            }
            dispatch(memberlogin(data));
        }
        
    }

    return (
        <div>
            <div className="headregislogin mb-3">
            {
                        LG == 'thai' ?  <div className="row m-0">
                        <div className="col-6 p-1 active">
                            <Link to={'/login'}>
                                <img className="gif" src="./images/loginbtn.gif"/>
                                <img className="png" src="./images/loginth.png"/>
                            </Link>
                        </div>
                        <div className="col-6 p-1">
                            <Link to={'/register'}>
                                <img className="gif" src="./images/regisbtn.gif"/>
                                <img className="png" src="./images/registh.png"/>
                            </Link>
                        </div>
                    </div> :  <div className="row m-0">
                    <div className="col-6 p-1 active">
                        <Link to={'/login'}>
                            <img className="gif" src="./images/user44/logingiff1.gif"/>
                            <img className="png" src="./images/login.png"/>
                        </Link>
                    </div>
                    <div className="col-6 p-1">
                        <Link to={'/register'}>
                            <img className="gif" src="./images/regisbtn.gif"/>
                            <img className="png" src="./images/regis.png"/>
                        </Link>
                    </div>
                </div>
                    }
               
            </div>

            <section className="sectionpage login">
                <div className="bginputlogin">
                   
                    <img className="ic-lg-01" alt={'chip'} src="./images/icon/chip.png"/>
                    <img className="ic-lg-02" alt={'card'} src="./images/icon/card.png"/>
                    <div className="logologin">
                        <Logo/>
                    </div>
                    <div className="inbgbeforelogin">

                        <div className="insidectloginmb">
                            <div className="headerlogin my-2"><h2>{lgtext[LG]['11']}</h2></div>
                            <form>
                                <div>
                                    <div className=" form-group my-2">
                                        <div>
                                            <label> {lgtext[LG]['2']}</label>
                                            <div className="el-input my-1">
                                                <i className="fas fa-user"/>
                                                <input type="text" ref={txtusername} placeholder={lgtext[LG]['2']}
                                                       className="inputstyle"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group my-4">
                                        <div>
                                            <label>{lgtext[LG]['3']}</label>
                                            <div className="el-input my-1">
                                                <i className="fas fa-lock"/>
                                                <input type="password" ref={txtpassword}  placeholder={lgtext[LG]['3']}
                                                       className="inputstyle"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            {
                        user.item?.status == 3 && <div className="text-danger text-center">{lgtext[LG]['13']}</div>
                }
                            <button className="btnLogin mt-3" onClick={loginpage}>
                                  <span>
                                  {lgtext[LG]['11']}
                                  </span>
                            </button>

                            <div className="wantregister"> {lgtext[LG]['12']}
                                <Link to={'/register'} className="ms-3 loginbtn"> {lgtext[LG]['1']}</Link>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Login;
